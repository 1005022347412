import { TypeColWithNamePropertyPlatform } from '../../../../../models/datagrid';
import i18next from 'i18next';
import {
  Button,
  DropdownMenu,
  IconButton,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';
import { formatDate } from '../../../../../utils/utils';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { commentsOrderAction } from '../../../../../features/datagrid/menu-entry';
import { getColumnOptions } from '../../../../../features/datagrid/datagrid.utils';
import { isInPendingStatus } from '../../../../../utils/order.utils';
import { ControlTabPermissions } from '../../../../../models/connected-user.tsx';

export const colControlAction = (
  connectedUserPermissions: ControlTabPermissions,
  callback: (data: ManufacturingOrder) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'controlledAt',
      i18next.t('datagrid.columns.control', { ns: 'production' }),
      1
    ),
    showColumnMenuTool: false,
    filterEditor: DateFilter,
    maxWidth: 180,
    filterEditorProps: () => {
      // for range and not in range operators, the index is 1 for the after field
      return {
        dateFormat: i18next.t('date.small', { ns: 'common' }),
        cancelButton: false
      };
    },
    render: ({ data }) => {
      if (data?.controlledAt) {
        return formatDate(new Date(data.controlledAt));
      } else if (connectedUserPermissions.canPerformControlActions) {
        const displayedButton = (
          <Button
            label={i18next.t('datagrid.action.control', { ns: 'production' })}
            onClick={() => callback(data)}
            size="s"
            isLoading={isInPendingStatus(data.currentStep)}
          />
        );
        if (isInPendingStatus(data.currentStep)) {
          return (
            <Tooltip>
              <TooltipContent>
                {i18next.t('pendingElement.tooltipContent', { ns: 'common' })}
              </TooltipContent>
              {displayedButton}
            </Tooltip>
          );
        } else {
          return displayedButton;
        }
      }
    }
  };
};

export const colControlMenu = (
  connectedUserPermissions: ControlTabPermissions,
  handleCommentCallback: (data: ManufacturingOrder) => void
): TypeColWithNamePropertyPlatform | undefined => {
  if (!connectedUserPermissions.canPerformControlActions) {
    return;
  }

  return {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      const kebabMenu = (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton
              data-cy="datagrid-menu"
              faIconClass="ellipsis-vertical"
              isActive={active}
              isDisabled={isInPendingStatus(data.currentStep)}
              radius="full"
            />
          )}
          data={[[commentsOrderAction(data, handleCommentCallback)]]}
        />
      );
      if (isInPendingStatus(data.currentStep)) {
        return (
          <Tooltip>
            <TooltipContent>
              {i18next.t('pendingElement.tooltipContent', { ns: 'common' })}
            </TooltipContent>
            {kebabMenu}
          </Tooltip>
        );
      } else {
        return kebabMenu;
      }
    }
  };
};
