import i18next from 'i18next';
import { TypeColWithNamePropertyPlatform } from '../../../../../models/datagrid';
import {
  Button,
  DropdownMenu,
  IconButton,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter/index';
import {
  ActionBtn,
  colDate,
  colDentistName,
  colFamilies,
  colInstructions,
  colManufacturingOrderProducts,
  colOrderNumber,
  colStatus
} from '../../../../../features/datagrid/columns';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';
import { commentsOrderAction } from '../../../../../features/datagrid/menu-entry';
import { getColumnOptions } from '../../../../../features/datagrid/datagrid.utils';
import { ManufacturingComponentView } from '../../../../../models/component.tsx';
import { Order } from '../../../../../models/order.tsx';
import { isInPendingStatus } from '../../../../../utils/order.utils.ts';
import { WorkflowPendingStepEnum, WorkflowStepEnum } from '../../../../../enum/workflow-step.ts';
import { ShippingTabPermissions } from '../../../../../models/connected-user.tsx';
import { AppDispatch } from '../../../../../store';
import { CommonTypes } from '../../../../../models/common-types.tsx';

export const colShippingAction = <
  T extends Order | ManufacturingOrder | ManufacturingComponentView
>(
  shipBtn?: ActionBtn<T>,
  deliveredBtn?: ActionBtn<T>
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'shipping',
      i18next.t('datagrid.columns.shipping', { ns: 'production' }),
      1
    ),
    showColumnMenuTool: false,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      // for range and not in range operators, the index is 1 for the after field
      return {
        dateFormat: i18next.t('date.small', { ns: 'common' }),
        cancelButton: false
      };
    },
    render: ({ data }) => {
      switch (data.currentStep) {
        case WorkflowPendingStepEnum.PENDING_DELIVERY:
        case WorkflowStepEnum.DELIVERY:
        case WorkflowPendingStepEnum.PENDING_SHIPPED:
          return (
            shipBtn && (
              <Button
                label={shipBtn.label}
                onClick={() => shipBtn.onClick(data)}
                size="s"
                isLoading={isInPendingStatus(data.currentStep)}
              />
            )
          );
        case WorkflowStepEnum.SHIPPED:
        case WorkflowPendingStepEnum.PENDING_DELIVERED:
          return (
            deliveredBtn && (
              <Button
                label={deliveredBtn.label}
                onClick={() => deliveredBtn.onClick(data)}
                size="s"
                isLoading={isInPendingStatus(data.currentStep)}
              />
            )
          );
      }
    }
  };
};

export const colShippingMenu = (
  handleCommentCallback: (data: ManufacturingOrder) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      const kebabMenu = (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton
              data-cy="datagrid-menu"
              faIconClass="ellipsis-vertical"
              isActive={active}
              isDisabled={isInPendingStatus(data.currentStep)}
              radius="full"
            />
          )}
          data={[[commentsOrderAction(data, handleCommentCallback)]]}
        />
      );
      if (isInPendingStatus(data.currentStep)) {
        return (
          <Tooltip>
            <TooltipContent>
              {i18next.t('pendingElement.tooltipContent', { ns: 'common' })}
            </TooltipContent>
            {kebabMenu}
          </Tooltip>
        );
      } else {
        return kebabMenu;
      }
    }
  };
};

export const getDatagridColumns = (
  connectedUserPermissions: ShippingTabPermissions,
  dispatch: AppDispatch,
  commonTypes: CommonTypes,
  handleClickComment: (manufacturingOrder: ManufacturingOrder) => void,
  handleClickShip: (manufacturingOrder: ManufacturingOrder) => void,
  handleClickDelivered: (manufacturingOrder: ManufacturingOrder) => void
) => {
  const commentActionBtn: ActionBtn<ManufacturingOrder> = {
    label: i18next.t('comments.comment', { ns: 'comment' }),
    onClick: (data: ManufacturingOrder) => {
      handleClickComment(data);
    }
  };

  const shipBtn: ActionBtn<ManufacturingOrder> = {
    label: i18next.t('datagrid.action.ship', { ns: 'production' }),
    onClick: (data: ManufacturingOrder) => {
      handleClickShip(data);
    }
  };

  const deliveredBtn: ActionBtn<ManufacturingOrder> = {
    label: i18next.t('datagrid.action.delivered', { ns: 'production' }),
    onClick: (data: ManufacturingOrder) => {
      handleClickDelivered(data);
    }
  };

  const allColumns = [
    colStatus,
    colOrderNumber(dispatch, commentActionBtn),
    colDentistName,
    colFamilies(commonTypes),
    colManufacturingOrderProducts(),
    colInstructions,
    colDate('limitShippingDate'),
    colDate('expectedDate')
  ];

  // Add shipping actions column if connected user is allowed to
  if (connectedUserPermissions.canPerformShippingActions) {
    allColumns.splice(4, 0, colShippingAction(shipBtn, deliveredBtn));
    allColumns.push(colShippingMenu(handleClickComment));
  }

  return allColumns;
};
