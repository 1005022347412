import { TypeColWithNameProperty } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { TypeColWithNamePropertyPlatform } from '../../models/datagrid';
import { CellProps } from '@inovua/reactdatagrid-community/types';
import DatagridHeader from './datagrid-header/DatagridHeader';

export const getColumnOptions = (
  name: string,
  header: string,
  defaultFlex?: number,
  sortable?: boolean // true by default
): Partial<TypeColWithNameProperty> => {
  let column: Partial<TypeColWithNamePropertyPlatform> = {
    name: name,
    headerAlign: 'start',
    // Custom rendering for 2 lines headers with ellipsis
    header: (cellProps: CellProps) => {
      cellProps.headerEllipsis = false;
      return <DatagridHeader> {header} </DatagridHeader>;
    },
    showColumnMenuTool: true,
    showColumnMenuToolOnHover: false,
    enableColumnFilterContextMenu: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left'
      }
    })
  };
  if (defaultFlex) {
    column = { ...column, defaultFlex: defaultFlex };
  }
  if (sortable !== undefined) {
    column = { ...column, sortable: sortable };
  }
  return column;
};

// For now, this works for a list
// If we need to go further, we'll refactor it or rename it if needed
export const computeMasterDetailsHeight = (
  rowHeight: number,
  rowYMargin: number,
  detailsYMargin: number,
  propertiesToCheck: (object | string)[]
) => {
  const nbOfRows =
    1 + propertiesToCheck.reduce((number, property) => (property ? number + 1 : number), 0);

  return nbOfRows * (rowHeight + rowYMargin) + detailsYMargin;
};
