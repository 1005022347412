import { PositionKey, PositionKeyString } from '../models/position';

export const notationToIndex: { [key in PositionKeyString]: number } = {
  '18': 0,
  '17': 1,
  '16': 2,
  '15': 3,
  '14': 4,
  '13': 5,
  '12': 6,
  '11': 7,
  '21': 8,
  '22': 9,
  '23': 10,
  '24': 11,
  '25': 12,
  '26': 13,
  '27': 14,
  '28': 15,
  '48': 16,
  '47': 17,
  '46': 18,
  '45': 19,
  '44': 20,
  '43': 21,
  '42': 22,
  '41': 23,
  '31': 24,
  '32': 25,
  '33': 26,
  '34': 27,
  '35': 28,
  '36': 29,
  '37': 30,
  '38': 31
};

export const OrderedLowerPositions: PositionKey[] = [
  48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38
];
Object.freeze(OrderedLowerPositions);

export const OrderedUpperPositions: PositionKey[] = [
  18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28
];
Object.freeze(OrderedUpperPositions);

export const sortedPositionsNumber: PositionKey[] = [
  ...OrderedUpperPositions,
  ...OrderedLowerPositions
];
Object.freeze(sortedPositionsNumber);

export const sortedUpperPositionsArray: Array<PositionKeyString> = OrderedUpperPositions.map(
  (pos) => pos.toString() as PositionKeyString
);
Object.freeze(sortedUpperPositionsArray);

export const sortedLowerPositionsArray: Array<PositionKeyString> = OrderedLowerPositions.map(
  (pos) => pos.toString() as PositionKeyString
);
Object.freeze(sortedLowerPositionsArray);

export const sortedPositionsString: Array<PositionKeyString> = [
  ...sortedUpperPositionsArray,
  ...sortedLowerPositionsArray
];
Object.freeze(sortedPositionsString);
