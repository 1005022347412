import styles from '../create-dentist-page.module.scss';
import { Button, Fieldset, RadioList } from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { newDentistSelector } from '../../../../../store/users/users.selectors';
import { Language, DentalNotation } from '../../../../../enum/user';
import { usersActions } from '../../../../../store/users/users.reducer';
import useForm from '../../../../../utils/useForm';
import { dentalNotations, languages } from '../../../../../utils/utils';

type Props = {
  nextCallback: () => void;
  previousCallback: () => void;
};

const CustomizationForm = ({ nextCallback, previousCallback }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dentist = useAppSelector(newDentistSelector);

  const saveCustomizationInfo = () => {
    dispatch(
      usersActions.setNewDentist({
        ...dentist,
        ...values
      })
    );
  };

  const onSubmit = () => {
    saveCustomizationInfo();
    nextCallback();
  };

  const handlePreviousButton = () => {
    saveCustomizationInfo();
    previousCallback();
  };

  const { values, handleSubmit, handleSelect } = useForm(
    {
      dentalNotation: dentist?.dentalNotation ? dentist.dentalNotation : DentalNotation.ISO,
      language: dentist?.language ? dentist.language : Language.fr_FR
    },
    onSubmit
  );

  return (
    <form onSubmit={handleSubmit} className={styles['create-dentist-page__form']}>
      <Fieldset size="m" className={styles['create-dentist-page__form__fieldset']}>
        <>
          <RadioList
            data-cy="dentalNotationRadio"
            title={t('createDentist.customization.dentalNotation', { ns: 'dentist' })}
            options={dentalNotations}
            selectedValue={values?.dentalNotation}
            name="dentalNotation"
            onClick={(newValue: DentalNotation) => handleSelect(newValue, 'dentalNotation')}
            className={styles['create-dentist-page__form__fieldset__input']}
          />
          <RadioList
            data-cy="languageRadio"
            title={t('createDentist.customization.language', { ns: 'dentist' })}
            options={languages}
            name="language"
            selectedValue={values?.language}
            onClick={(newValue: Language) => handleSelect(newValue, 'language')}
          />
        </>
      </Fieldset>
      <div className="form__submit-button form__submit-button--double">
        <Button
          label={t('action.previous', { ns: 'common' })}
          category="secondary"
          onClick={handlePreviousButton}
          iconLeft="fa-chevron-left"
        />
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
          data-cy="nextButton"
        />
      </div>
    </form>
  );
};
export default CustomizationForm;
