import { configureStore, createAction, Reducer, UnknownAction } from '@reduxjs/toolkit';
import { AuthenticationReducer, UsersReducer } from '../models/user';
import { authSlice } from './auth/auth.reducers';
import { rtkQueryErrorLogger } from '../services/errors.services';
import { feedbackSlice } from './feedback/feedback.reducer';
import { FeedbackReducer } from '../models/feedback';
import { usersSlice } from './users/users.reducer';
import { EstablishmentReducer } from '../models/establishment';
import { establishmentSlice } from './establishment/establishment.reducer';
import { api } from '../services/api';
import { establishmentsApi } from '../services/establishments-api.services';
import { DatagridsSettingsReducer } from '../models/datagrid';
import { datagridsSettingsSlice } from './datagrids-settings/datagrids-settings.reducers';
import { OrdersReducer } from '../models/order';
import { ordersSlice } from './orders/orders.reducer';
import { MapReducer } from '../models/map';
import { mapSlice } from './map/map.reducer';
import { productCustomizationSlice } from './product-customization/productCustomization.reducer';
import { ProductCustomizationReducer } from '../models/product-customization';

interface ReducerInterface {
  authenticationState: Reducer<AuthenticationReducer, UnknownAction>;
  usersState: Reducer<UsersReducer, UnknownAction>;
  ordersState: Reducer<OrdersReducer, UnknownAction>;
  feedbackState: Reducer<FeedbackReducer, UnknownAction>;
  establishmentState: Reducer<EstablishmentReducer, UnknownAction>;
  datagridsSettingsState: Reducer<DatagridsSettingsReducer, UnknownAction>;
  mapState: Reducer<MapReducer, UnknownAction>;
  productCustomizationState: Reducer<ProductCustomizationReducer, UnknownAction>;
}

const reducer: ReducerInterface = {
  authenticationState: authSlice.reducer,
  usersState: usersSlice.reducer,
  ordersState: ordersSlice.reducer,
  feedbackState: feedbackSlice.reducer,
  establishmentState: establishmentSlice.reducer,
  datagridsSettingsState: datagridsSettingsSlice.reducer,
  mapState: mapSlice.reducer,
  productCustomizationState: productCustomizationSlice.reducer
};

const store = configureStore({
  reducer: {
    ...reducer,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(establishmentsApi.middleware)
      .concat(rtkQueryErrorLogger)
});

export const resetAll = createAction('RESET_ALL');

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
