import moment from 'moment';
import { Order, OrderForCreation, OrderForUpdate } from '../../../models/order';

import { CheckboxStringItem } from '../../../models/form';

type FinalisationFormInfo = {
  dentistEmail: string;
  expectedDate: string | Date;
  instructions: string;
  tags: CheckboxStringItem[];
};

export const getEditFormInitValues = (
  initialOrderInfo: Order,
  tags: CheckboxStringItem[]
): FinalisationFormInfo => {
  return {
    dentistEmail: initialOrderInfo?.dentistEmail || '',
    expectedDate: moment(initialOrderInfo?.expectedDate).format('YYYY-MM-DD') || '',
    instructions: initialOrderInfo?.instructions || '',
    tags: tags || []
  };
};

export const getCreateFormInitValues = (
  orderToCreate: OrderForCreation | OrderForUpdate,
  tags: CheckboxStringItem[]
): FinalisationFormInfo => {
  return {
    dentistEmail: orderToCreate.dentistEmail || '',
    expectedDate: orderToCreate.expectedDate || '',
    instructions: orderToCreate.instructions || '',
    tags: tags || []
  };
};
