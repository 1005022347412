import { Dispatch, SetStateAction, useEffect } from 'react';
import styles from './reject-order-form.module.scss';
import {
  AttentionBox,
  Box,
  Button,
  CheckboxList,
  TextareaField
} from '@platform-storybook/circlestorybook';
import { useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { attentionBoxErrorSelector } from '../../../../store/feedback/feedback.selector';
import { getMessageError } from '../../../../utils/utils';
import useForm from '../../../../utils/useForm';
import {
  useRefuseOrderMutation,
  usePatchOrderMutation
} from '../../../../services/orders-api.services';
import { useGetConnectedUserQuery } from '../../../../services/users-api.services';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import { RejectCause } from '../../../../enum/order';
import { CheckboxStringItem } from '../../../../models/form';
import { StringObject } from '../../../../models/common';

type props = {
  onRejectOrderFormSubmitCallback: (causes?: string[], rejectCauseComment?: string) => void;
  orderNumber: string;
  isLoading: boolean;
  setAreActionsDisabled: Dispatch<SetStateAction<boolean>>;
};

const RejectOrderForm = ({
  onRejectOrderFormSubmitCallback,
  orderNumber,
  isLoading,
  setAreActionsDisabled
}: props) => {
  const { data: connectedUser } = useGetConnectedUserQuery();
  const [patchOrder] = usePatchOrderMutation();
  const { t } = useTranslation(['orderDetail']);

  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);

  const [
    rejectOrder,
    {
      isLoading: isLoadingRejectOrder,
      error: errorRejectOrder,
      reset,
      isSuccess: isSuccessRejectOrder
    }
  ] = useRefuseOrderMutation();

  const onSubmit = async () => {
    setAreActionsDisabled(true);
    const causes = (values.rejectCauses as Array<CheckboxStringItem>)
      ?.filter((cause) => cause.isChecked)
      ?.map((cause) => cause.value) as Array<RejectCause>;

    // Patch order with assignee, so that the email could be used in the situation where we have no claims (orchestrate)
    await patchOrder({
      orderNumber: orderNumber,
      assigneeEmail: connectedUser?.email,
      assigneeName: connectedUser?.firstName + ' ' + connectedUser?.lastName
    });
    await rejectOrder({
      orderNumber: orderNumber,
      isApproved: false,
      rejectCauses: causes.length ? causes : undefined,
      rejectCauseComment: values.rejectCauseComment as string
    });
  };

  useEffect(() => {
    if (isSuccessRejectOrder) {
      onRejectOrderFormSubmitCallback(
        (values.rejectCauses as Array<CheckboxStringItem>)
          ?.filter((cause) => cause.isChecked)
          ?.map((cause) => t(`rejectOrderForm.rejectCauses.${cause.value}`)),
        values.rejectCauseComment as string
      );
    }
  }, [isSuccessRejectOrder]);

  const causes: Array<CheckboxStringItem> = Object.values(RejectCause).map((cause) => {
    return {
      value: cause,
      label: t(`rejectOrderForm.rejectCauses.${cause}`),
      isChecked: false
    };
  });

  const validateCallback = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (key === 'rejectCauseComment' && !values[key]) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, errors, handleSubmit, handleCheck, handleBlur, handleChange } = useForm(
    {
      rejectCauses: causes,
      rejectCauseComment: ''
    },
    onSubmit,
    validateCallback
  );

  return (
    <>
      {errorRejectOrder && attentionBoxError ? (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={getMessageError(errorRejectOrder)}
          className={styles['reject-order-form__attention-box']}
          onClose={() => reset()}
        />
      ) : (
        ''
      )}
      <Box color={ColorPropsEnum.WHITE} className={styles['reject-order-form__box']}>
        <form onSubmit={handleSubmit} className={styles['reject-order-form__box__form']}>
          <CheckboxList
            data-cy="rejectCauses"
            title={t('rejectOrderForm.form.rejectCauses')}
            data={values.rejectCauses}
            onClick={(newValue: string) => handleCheck(newValue, 'rejectCauses')}
            isDisabled={isLoading}
          />
          <TextareaField
            data-cy="rejectCauseComment"
            id={'rejectCauseComment'}
            name="rejectCauseComment"
            label={t('rejectOrderForm.form.rejectCauseComment')}
            value={values.rejectCauseComment}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={
              errors?.rejectCauseComment
                ? t('rejectOrderForm.form.rejectCauseCommentMandatory')
                : undefined
            }
            variant={errors.rejectCauseComment ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            isDisabled={isLoading}
          />
          <div className="form__submit-button form__submit-button--right">
            <Button
              data-cy="rejectOrderButton"
              label={t('rejectOrderForm.action')}
              isLoading={isLoadingRejectOrder || isLoading}
              type="submit"
            />
          </div>
        </form>
      </Box>
    </>
  );
};

export default RejectOrderForm;
