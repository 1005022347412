import { api } from './api';
import { FullProduct, ProductListResponse } from '../models/product';
import {
  PatchManufacturingComponentToothBody,
  PatchManufacturingStepBody
} from '../enum/manufacturing-step.enum';

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    setComponentManufactured: builder.mutation({
      query: ({ productId, componentId }: { productId: number; componentId: number }) => ({
        url: `/manufacturing/products/${productId}/components/${componentId}/manufactured`,
        method: 'POST'
      })
    }),
    getEstimatedDeliveryDate: builder.mutation({
      query: (productIds: number[]) => ({
        url: '/products/estimateDeliveryDate',
        method: 'POST',
        body: { productIds }
      })
    }),
    patchManufacturingStepComponent: builder.mutation({
      query: ({ productId, componentId, body }: PatchManufacturingStepBody) => ({
        url: `/manufacturing/products/${productId}/components/${componentId}`,
        method: 'PATCH',
        body
      })
    }),
    patchManufacturingProcess: builder.mutation({
      query: ({
        productId,
        componentId,
        manufacturingProcess
      }: PatchManufacturingComponentToothBody) => ({
        url: `/manufacturing/products/${productId}/components/${componentId}`,
        method: 'PATCH',
        body: { manufacturingProcess }
      })
    }),
    getAllProducts: builder.query<ProductListResponse, void>({
      query: () => ({
        url: '/products?limit=-1&filter.deletedAt=$null&filter.isChairSide=0',
        method: 'GET'
      })
    }),
    getOneProduct: builder.query<FullProduct, number>({
      query: (productId: number) => ({
        url: `/products/${productId}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useSetComponentManufacturedMutation,
  usePatchManufacturingStepComponentMutation,
  usePatchManufacturingProcessMutation,
  useGetAllProductsQuery,
  useLazyGetOneProductQuery,
  useGetEstimatedDeliveryDateMutation
} = productsApi;
