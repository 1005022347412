import { ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BreadcrumbItem,
  BreadcrumbsBar,
  Button,
  Dialog,
  SideBarModal,
  TabItem,
  Tabs,
  Text,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import styles from './order-detail.module.scss';
import DetailTab from './tabs/detail-tab/DetailTab';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { useTranslation } from 'react-i18next';
import RejectOrderForm from './reject-order-form/RejectOrderForm';
import PrintOrder from '../../../features/print-order/PrintOrder';
import TeethMapDisplay from './tabs/detail-tab/teethmap-display/TeethMapDisplay';
import {
  useLazyGetOneOrderQuery,
  usePatchOrderMutation,
  useSubmitOrderMutation
} from '../../../services/orders-api.services';
import { isInPendingStatus, isSubmitted, isValidated } from '../../../utils/order.utils';
import { Order, OrderFile } from '../../../models/order';
import Comments from '../../../features/comments/Comments';
import { useGetAllDentistsQuery, useGetLabUsersQuery } from '../../../services/users-api.services';
import { splitOrderNumber } from '../../../features/order-manager/teeth-map/utils';
import ValidateOrderForm from './validate-order-form/ValidateOrderForm';
import {
  useDownloadFileFromStorageMutation,
  useLazyGetOneDownloadableFileQuery
} from '../../../services/files-api.services';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { loadOrderFilesData } from '../../../features/file-manager/file.utils';
import { ordersActions } from '../../../store/orders/orders.reducer';
import HistoryTab from './tabs/history/HistoryTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';
import PendingTooltip from '../../../features/pending-tooltip/PendingTooltip';
import { REFRESH_DELAY } from '../../../utils/utils';
import { WorkflowStepEnum } from '../../../enum/workflow-step';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../enum/feedback';
import { getOrderDetailsPagePermissionsSelector } from '../../../store/auth/permissions.selectors.tsx';

enum FormToDisplayEnum {
  NONE = 'NONE',
  REJECT_ORDER = 'REJECT_ORDER',
  ACCEPT_ORDER = 'ACCEPT_ORDER'
}

const OrderDetailPage = () => {
  const { t } = useTranslation(['orderDetail']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orderNumber } = useParams();
  const [getOneOrder, { data: orderData, isLoading: isOrderLoading }] = useLazyGetOneOrderQuery();
  const [getOneDownloadableFile] = useLazyGetOneDownloadableFileQuery();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();
  const connectedUserPermissions = useAppSelector(getOrderDetailsPagePermissionsSelector);
  const [submitOrder, { isSuccess: isSubmitSuccess }] = useSubmitOrderMutation();
  const [patchOrder, { isSuccess: isSuccessPatchOrder }] = usePatchOrderMutation();

  const [refreshTimer, setRefreshTimer] = useState<NodeJS.Timeout | null>(null);
  const [order, setOrder] = useState<Order | undefined>();
  const [orderIsPending, setOrderIsPending] = useState<boolean>(false);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [isOrderValidated, setIsOrderValidated] = useState<boolean | undefined>(undefined);
  const isOrderSubmitted = isSubmitted(order?.currentStep);
  const [isSideBarOpened, setIsSideBarOpened] = useState(false);
  const [formToDisplay, setFormToDisplay] = useState<FormToDisplayEnum>(FormToDisplayEnum.NONE);
  // todo remove this after event management is implemented https://gitlab.com/anatoscope/circle/dev/platform/pub-sub/-/issues/5
  const [skipManufacturingOrderLoad, setSkipManufacturingOrderLoad] = useState<boolean>(false);
  const [areActionsDisabled, setAreActionsDisabled] = useState<boolean>(false);
  const [isConfirmSubmitDialogOpened, setIsConfirmSubmitDialogOpened] = useState(false);
  const [expectedStepAfterPoll, setExpectedStepAfterPoll] = useState<WorkflowStepEnum[] | null>(
    null
  );
  const [toastSuccessMessageAfterPoll, setToastSuccessMessageAfterPoll] = useState<string | null>();

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const tabItems: ReactElement[] = [];
  if (connectedUserPermissions.canViewDetail) {
    tabItems.push(
      <TabItem
        key="detail"
        label={t('tabs.detail.title')}
        className={styles['order-detail__content__tab__content']}
        data-cy="detail-tab">
        {order && (
          <DetailTab
            order={order}
            skipManufacturingOrderLoad={skipManufacturingOrderLoad}
            isDisabled={orderIsPending || areActionsDisabled}
          />
        )}
      </TabItem>
    );
  }
  if (connectedUserPermissions.canViewComments) {
    tabItems.push(
      <TabItem
        key="comments"
        label={t('tabs.comments.title')}
        className={styles['order-detail__content__tab__content']}
        counter={order?.comments?.length}>
        {orderNumber && <Comments orderNumber={orderNumber} inSidebar={false} />}
      </TabItem>
    );
  }
  if (connectedUserPermissions.canViewHistory) {
    tabItems.push(
      <TabItem
        key="history"
        label={t('tabs.history.title')}
        className={styles['order-detail__content__tab__content']}>
        {connectedUserPermissions.canViewHistory && orderNumber && (
          <HistoryTab orderNumber={orderNumber} />
        )}
      </TabItem>
    );
  }

  // Load users lab in order to have data already loaded for Comment and history tab.
  useGetLabUsersQuery();
  // Load dentist in order to have data already loaded for History tab.
  useGetAllDentistsQuery();

  const startRefreshTimer = () => {
    if (refreshTimer) {
      clearTimeout(refreshTimer);
    }
    const newTimer = setTimeout(() => {
      if (orderNumber) {
        setIsPolling(true);
        getOneOrder(orderNumber);
      }
    }, REFRESH_DELAY);
    setRefreshTimer(newTimer);
  };

  useEffect(() => {
    dispatch(ordersActions.resetOrder());
    if (orderNumber) getOneOrder(orderNumber);

    return () => {
      dispatch(ordersActions.resetOrder());
    };
  }, []);

  useEffect(() => {
    if (orderData && orderNumber) {
      setOrder(orderData);
      const orderIsPendingStatus = isInPendingStatus(orderData?.currentStep);
      setOrderIsPending(orderIsPendingStatus);
      const orderValidated = isValidated(orderData?.currentStep);
      setIsOrderValidated(orderValidated);
      loadOrderFilesData(
        dispatch,
        getOneDownloadableFile,
        downloadFromStorage,
        orderNumber,
        orderData.patient?.diagnostic?.patientFiles as OrderFile[]
      );
      // If we are in the specific case of polling, we handle the toast and reset the states
      if (isPolling) {
        if (
          expectedStepAfterPoll?.includes(orderData.currentStep as WorkflowStepEnum) &&
          toastSuccessMessageAfterPoll
        ) {
          dispatch(
            feedbackActions.setToast({
              message: toastSuccessMessageAfterPoll,
              type: ToastType.SUCCESS
            })
          );
        } else {
          dispatch(
            feedbackActions.setToast({
              message: t('error.generic', { ns: 'common' }),
              type: ToastType.DANGER
            })
          );
        }
        setIsPolling(false);
        setExpectedStepAfterPoll(null);
        setToastSuccessMessageAfterPoll(null);
        setIsConfirmSubmitDialogOpened(false);
        setIsSideBarOpened(false);
        setFormToDisplay(FormToDisplayEnum.NONE);
        setSkipManufacturingOrderLoad(true);
        setAreActionsDisabled(false);
      }
    }
  }, [orderData]);

  useEffect(() => {
    if (isSuccessPatchOrder) {
      if (orderNumber) {
        submitOrder(orderNumber);
      }
    }
  }, [isSuccessPatchOrder]);

  useEffect(() => {
    if (isSubmitSuccess) {
      startRefreshTimer();
      setExpectedStepAfterPoll([WorkflowStepEnum.VALIDATION]);
      setToastSuccessMessageAfterPoll(t('submitOrderDialog.success', { orderNumber: orderNumber }));
    }
  }, [isSubmitSuccess]);

  const orderNumberSplit = orderNumber ? splitOrderNumber(orderNumber) : undefined;

  const submit = async () => {
    setAreActionsDisabled(true);
    if (orderNumber) {
      patchOrder({
        orderNumber: orderNumber,
        submissionDate: null
      });
    }
  };

  const rejectBtn = (
    <Button
      label={t('actions.reject')}
      variant={ColorPropsEnum.DANGER}
      iconLeft="fa-ban"
      className={styles['order-detail__content__header__actions--not-first']}
      onClick={() => {
        setIsSideBarOpened(true);
        setFormToDisplay(FormToDisplayEnum.REJECT_ORDER);
      }}
      category="secondary"
      size="s"
      isDisabled={orderIsPending || areActionsDisabled}
      data-cy="button-reject"
    />
  );

  const acceptBtn = (
    <Button
      label={t('actions.accept')}
      variant={ColorPropsEnum.SUCCESS}
      iconLeft="fa-check"
      className={styles['order-detail__content__header__actions--not-first']}
      onClick={() => {
        setIsSideBarOpened(true);
        setFormToDisplay(FormToDisplayEnum.ACCEPT_ORDER);
      }}
      category="primary"
      size="s"
      isDisabled={orderIsPending || areActionsDisabled}
      data-cy="button-accept"
    />
  );

  const sendToValidationBtn = (
    <Button
      label={t('actions.sendToValidation')}
      className={styles['order-detail__content__header__actions--not-first']}
      onClick={() => {
        setIsConfirmSubmitDialogOpened(true);
      }}
      category="primary"
      variant="success"
      size="s"
      isDisabled={orderIsPending || areActionsDisabled}
      data-cy="button-send"
    />
  );

  const printBtn = (
    <Button
      label={t('actions.print')}
      iconLeft="fa-print"
      className={styles['order-detail__content__header__actions--not-first']}
      onClick={() => {
        document.getElementById('printTrigger')!.click();
      }}
      category="primary"
      size="s"
      isDisabled={orderIsPending || areActionsDisabled}
      data-cy="button-print"
    />
  );

  const editBtn = (
    <Button
      label={t('action.edit', { ns: 'common' })}
      iconLeft="fa-pen-to-square"
      onClick={() => {
        navigate(`/order/${orderNumber}/edit`);
      }}
      category="primary"
      size="s"
      isDisabled={orderIsPending || areActionsDisabled || isOrderValidated}
      data-cy="button-edit"
    />
  );

  return (
    orderNumberSplit && (
      <div>
        <Dialog
          title={t('submitOrderDialog.title', {
            orderNumber: orderNumberSplit.lastPart
          })}
          isOpened={isConfirmSubmitDialogOpened}
          isLoading={areActionsDisabled}
          cancelButtonLabel={t('action.cancel', { ns: 'common' })}
          confirmButtonLabel={t('actions.sendToValidation')}
          onCancel={() => setIsConfirmSubmitDialogOpened(false)}
          onConfirm={submit}
        />
        <BreadcrumbsBar>
          <BreadcrumbItem text={t('link.dashboard', { ns: 'common' })} link="/dashboard" />
          <BreadcrumbItem link="#" text={t('title')} />
        </BreadcrumbsBar>
        <div className={styles['order-detail']}>
          <div className={styles['order-detail__content']}>
            <header className={styles['order-detail__content__header']}>
              <div className={styles['order-detail__content__header__title']}>
                {order?.isInError && (
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className={styles['order-detail__content__header__title--error']}
                    data-cy="order_detail_title_error"
                  />
                )}
                <Text
                  type="title"
                  label={orderNumberSplit.beforeLastPart}
                  size="s"
                  color={order?.isInError ? 'danger' : undefined}
                  data-cy="order_detail_title"
                />
                <Text
                  type="title"
                  label={orderNumberSplit.lastPart}
                  bold
                  size="s"
                  color={order?.isInError ? 'danger' : undefined}
                  data-cy="order_detail_title"
                />
              </div>
              <div className={styles['order-detail__content__header__actions']} data-cy="actions">
                {connectedUserPermissions.canEditOrder && !isOrderLoading && isOrderValidated && (
                  <Tooltip>
                    <TooltipContent>{t(`tabs.detail.tooltips.cannotEdit`)}</TooltipContent>
                    {editBtn}
                  </Tooltip>
                )}
                {connectedUserPermissions.canEditOrder &&
                  !isOrderLoading &&
                  !isOrderValidated &&
                  orderIsPending && <PendingTooltip>{editBtn}</PendingTooltip>}
                {connectedUserPermissions.canEditOrder &&
                  !isOrderLoading &&
                  !isOrderValidated &&
                  !orderIsPending &&
                  editBtn}
                {!isOrderLoading && isOrderSubmitted && isOrderValidated && orderIsPending && (
                  <PendingTooltip>{printBtn}</PendingTooltip>
                )}
                {connectedUserPermissions.canAdministrateOrder &&
                  !isOrderLoading &&
                  isOrderSubmitted &&
                  isOrderValidated &&
                  !orderIsPending &&
                  printBtn}
                {connectedUserPermissions.canAdministrateOrder &&
                  !isOrderLoading &&
                  !isOrderSubmitted &&
                  orderIsPending && <PendingTooltip>{sendToValidationBtn}</PendingTooltip>}
                {connectedUserPermissions.canAdministrateOrder &&
                  !isOrderLoading &&
                  !isOrderSubmitted &&
                  !orderIsPending &&
                  sendToValidationBtn}
                {connectedUserPermissions.canAdministrateOrder &&
                  !isOrderLoading &&
                  isOrderSubmitted &&
                  !isOrderValidated &&
                  orderIsPending && (
                    <>
                      <PendingTooltip>{rejectBtn}</PendingTooltip>
                      <PendingTooltip>{acceptBtn}</PendingTooltip>
                    </>
                  )}
                {connectedUserPermissions.canAdministrateOrder &&
                  !isOrderLoading &&
                  isOrderSubmitted &&
                  !isOrderValidated &&
                  !orderIsPending && (
                    <>
                      {rejectBtn}
                      {acceptBtn}
                    </>
                  )}
              </div>
            </header>
            <Tabs
              activeTabId={0}
              id="order-detail-tabs"
              className={styles['order-detail__content__tab']}
              operatingMode="index">
              {tabItems.map((tabItem) => tabItem)}
            </Tabs>
          </div>
        </div>
        {formToDisplay === FormToDisplayEnum.REJECT_ORDER && (
          <SideBarModal
            title={t('rejectOrderForm.title', {
              orderNumber: order?.orderNumber
            })}
            isOpened={isSideBarOpened}
            closeOnOutsideClick={true}
            onClose={() => {
              setIsSideBarOpened(false);
              setFormToDisplay(FormToDisplayEnum.NONE);
            }}
            data-cy="reject-order-sidebar">
            <RejectOrderForm
              onRejectOrderFormSubmitCallback={() => {
                startRefreshTimer();
                setExpectedStepAfterPoll([WorkflowStepEnum.SUBMITTING]);
                setToastSuccessMessageAfterPoll(t('rejectOrderForm.success'));
              }}
              orderNumber={order!.orderNumber}
              setAreActionsDisabled={setAreActionsDisabled}
              isLoading={orderIsPending || areActionsDisabled}
            />
          </SideBarModal>
        )}
        {formToDisplay === FormToDisplayEnum.ACCEPT_ORDER && (
          <SideBarModal
            title={t('validateOrderForm.title', {
              orderNumber: order?.orderNumber
            })}
            isOpened={isSideBarOpened}
            closeOnOutsideClick={true}
            onClose={() => {
              setIsSideBarOpened(false);
              setFormToDisplay(FormToDisplayEnum.NONE);
            }}
            data-cy="accept-order-sidebar">
            <ValidateOrderForm
              onValidateOrderSubmit={() => {
                startRefreshTimer();
                setExpectedStepAfterPoll([
                  WorkflowStepEnum.MODELING,
                  WorkflowStepEnum.MODELING_ANALYZE,
                  WorkflowStepEnum.MODELING_DESIGN
                ]);
                setToastSuccessMessageAfterPoll(t('validateOrderForm.success'));
              }}
              orderNumber={order!.orderNumber}
              isMultiFamilyOrder={order!.families?.length > 1}
              setAreActionsDisabled={setAreActionsDisabled}
              isLoading={orderIsPending || areActionsDisabled}
            />
          </SideBarModal>
        )}
        <div className={styles['order-detail__print']}>
          {!isOrderLoading && order && (
            <div>
              {
                // @ts-expect-error it implements the feature like the library says.
                <button id="printTrigger" onClick={reactToPrintFn} />
              }
              <PrintOrder
                ref={contentRef}
                order={order}
                existingTeethmap={<TeethMapDisplay order={order} displayProductBubbles={false} />}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default OrderDetailPage;
