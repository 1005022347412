import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  buildManufacturingOrdersFilters,
  buildSort,
  rowClassname
} from '../../../../../features/datagrid/columns';
import DatagridFeature from '../../../../../features/datagrid/DatagridFeature';
import { Button, SideBarModal } from '@platform-storybook/circlestorybook';
import { FilterReducer, LoadDataProps } from '../../../../../models/datagrid';
import { useGetCommonTypesQuery } from '../../../../../services/common-types-api.services';
import {
  useLazyGetManufacturingOrdersQuery,
  useSetManufacturingOrderDeliveredMutation
} from '../../../../../services/manufacturing-orders-api.services';
import { datagridSettingsActions } from '../../../../../store/datagrids-settings/datagrids-settings.reducers';
import { shippingDatagridStateSelector } from '../../../../../store/datagrids-settings/datagrids-settings.selectors';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import styles from '../../production-page.module.scss';
import { getDatagridColumns } from './shipping';
import { WorkflowStepEnum } from '../../../../../enum/workflow-step';
import ShipOrderForm from './ship-order-form/ShipOrderForm';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';
import Comments from '../../../../../features/comments/Comments';
import DatagridReload from '../../../../../features/datagrid/DatagridReload';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../../enum/feedback';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { getShippingTabPermissionsSelector } from '../../../../../store/auth/permissions.selectors.tsx';
import { REFRESH_DELAY } from '../../../../../utils/utils';
import { useLazyGetOrdersIndicatorsQuery } from '../../../../../services/orders-api.services.ts';

const ShippingPage = () => {
  const datagridSettings = useAppSelector(shippingDatagridStateSelector);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedOrder, setSelectedOrder] = useState<ManufacturingOrder | null>(null);
  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [displayShippingForm, setDisplayShippingForm] = useState<boolean>(false);
  const [displayCommentsForm, setDisplayCommentsForm] = useState<boolean>(false);
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [reloadDate, setReloadDate] = useState<Date>();
  const [refreshTimer, setRefreshTimer] = useState<NodeJS.Timeout | null>(null);
  const [setManufacturingOrderDelivered, { isSuccess: isManufacturingOrderDeliveredSuccess }] =
    useSetManufacturingOrderDeliveredMutation();
  const [getOrdersIndicators] = useLazyGetOrdersIndicatorsQuery();

  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const [getManufacturingOrders] = useLazyGetManufacturingOrdersQuery();
  const connectedUserPermissions = useAppSelector(getShippingTabPermissionsSelector);

  const startRefreshTimer = () => {
    if (refreshTimer) {
      clearTimeout(refreshTimer);
    }
    const newTimer = setTimeout(() => {
      setIsReloadNeeded(true);
      getOrdersIndicators('');
    }, REFRESH_DELAY);
    setRefreshTimer(newTimer);
  };

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = buildManufacturingOrdersFilters(filterValue!, WorkflowStepEnum.DELIVERY);
    const sort = buildSort(sortInfo);
    return getManufacturingOrders({ page, limit, filters, sort })
      .unwrap()
      .then((orders) => {
        setReloadDate(new Date());
        return { data: orders.data, count: orders.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };

  const dataSource = useCallback(loadData, [commonTypes, isReloadNeeded]);

  useEffect(() => {
    if (isReloadNeeded) {
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded]);

  useEffect(() => {
    if (isManufacturingOrderDeliveredSuccess) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.deliveredManufacturingOrder', { ns: 'production' }),
          type: ToastType.SUCCESS
        })
      );
      setIsReloadNeeded(true);
      startRefreshTimer();
    }
  }, [isManufacturingOrderDeliveredSuccess]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'shipping',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const handleClickShip = (order: ManufacturingOrder) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setDisplayShippingForm(true);
  };

  const handleClickDelivered = (order: ManufacturingOrder) => {
    if (order.shipping)
      setManufacturingOrderDelivered({
        orderNumber: order.orderNumber,
        shippingId: order.shipping.id
      });
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    dispatch(
      datagridSettingsActions.setDatagridSort({
        datagrid: 'shipping',
        sort: newSortInfo as TypeSingleSortInfo
      })
    );
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetDatagridFilters({ datagrid: 'shipping' }));
  };

  const onSubmitShipOrder = () => {
    setSideBarOpened(false);
    setDisplayShippingForm(false);
    setIsReloadNeeded(true);
    startRefreshTimer();
  };

  const handleClickCloseSidebar = () => {
    setSideBarOpened(false);
    setDisplayShippingForm(false);
    setDisplayCommentsForm(false);
  };

  const handleClickComment = (order: ManufacturingOrder) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setDisplayCommentsForm(true);
  };

  return (
    <>
      <div className={styles['production-page__filters']}>
        <Button
          category="tertiary"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={removeFilters}
        />
        {reloadDate && (
          <DatagridReload reloadDate={reloadDate} setIsReloadNeeded={setIsReloadNeeded} />
        )}
      </div>
      {!areCommonTypesLoading && commonTypes && (
        <DatagridFeature
          key="shipping"
          style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
          dataSource={dataSource}
          filterValue={datagridSettings.filters}
          onFilterValueChange={onFilterValueChange}
          sortInfo={datagridSettings.sort}
          onSortInfoChange={onSortInfoChange}
          columns={getDatagridColumns(
            connectedUserPermissions,
            dispatch,
            commonTypes,
            handleClickComment,
            handleClickShip,
            handleClickDelivered
          )}
          rowClassName={rowClassname}
        />
      )}

      {displayShippingForm && selectedOrder && (
        <SideBarModal
          title={t('shipOrderModal.title', {
            orderNumber: selectedOrder.orderNumber,
            ns: 'production'
          })}
          isOpened={sideBarOpened}
          closeOnOutsideClick={true}
          onClose={handleClickCloseSidebar}>
          <ShipOrderForm
            onSubmitSetShippingCallback={onSubmitShipOrder}
            dentistEmail={selectedOrder.dentistEmail}
            orderNumber={selectedOrder.orderNumber}
          />
        </SideBarModal>
      )}
      {displayCommentsForm && selectedOrder?.orderNumber && (
        <SideBarModal
          title={t('comments.title', {
            orderNumber: selectedOrder.orderNumber,
            ns: 'comment'
          })}
          isOpened={sideBarOpened}
          closeOnOutsideClick={true}
          onClose={handleClickCloseSidebar}>
          <Comments
            orderNumber={selectedOrder.orderNumber}
            inSidebar={true}
            onFirstCommentAddedCallback={() => setIsReloadNeeded(true)}
          />
        </SideBarModal>
      )}
    </>
  );
};

export default ShippingPage;
