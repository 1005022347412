import { DentistInfos, LabUser, UsersReducer } from '../../models/user';
import { usersApi } from '../../services/users-api.services';
import { createSelector } from '@reduxjs/toolkit';
import { connectedUserSelector } from '../auth/auth.selectors';
import i18next from 'i18next';

interface ReducerState {
  usersState: UsersReducer;
}

const selectConnectedUserResult = usersApi.endpoints.getConnectedUser.select();
// Automatically fetch connected user & use the response to compute whether they're admin or not
export const connectedUserInfoSelector = createSelector(
  selectConnectedUserResult,
  (selectConnectedUserResult) => {
    return selectConnectedUserResult?.data;
  }
);

export const newDentistSelector = (state: ReducerState) => {
  return state.usersState.newDentist;
};

export const dentistSelector = (state: ReducerState) => {
  return state.usersState.dentist;
};

export const labUsersSelector = usersApi.endpoints.getLabUsers.select();

export const dentistsSelector = usersApi.endpoints.getAllDentists.select();

export const getDentistsListForDropdownSelector = () => {
  return createSelector([dentistsSelector], (dentists) => {
    if (dentists?.data && dentists.data.data.length > 0) {
      const users = dentists.data.data;
      sortUsersByFirstName(users);
      return [mapUsersToDropdown(users, true)];
    }

    return [];
  });
};

export const getLabUsersListForDropdownSelector = () => {
  return createSelector([labUsersSelector, connectedUserSelector], (labUsers, connectedUser) => {
    if (labUsers?.data && labUsers.data.length > 0) {
      const self = labUsers.data?.filter((user: LabUser) => user.email === connectedUser?.email);
      const otherUsers = labUsers.data.filter(
        (user: LabUser) => user.email !== connectedUser?.email
      );
      sortUsersByFirstName(otherUsers);
      return self && self.length > 0
        ? [mapUsersToDropdown(self), mapUsersToDropdown(otherUsers)]
        : [mapUsersToDropdown(otherUsers)];
    }

    return [];
  });
};

const sortUsersByFirstName = (users: LabUser[] | DentistInfos[]) => {
  [...users].sort((a: LabUser | DentistInfos, b: LabUser | DentistInfos) => {
    return (a.firstName as string).localeCompare(b.firstName as string);
  });
};

const mapUsersToDropdown = (users: LabUser[] | DentistInfos[], withRole = false) =>
  users?.map((user: LabUser | DentistInfos) => {
    const userRole = i18next.t(`user.roles.${user.role?.toLowerCase()}`, {
      ns: 'common'
    });
    const label = withRole
      ? `${user.firstName} ${user.lastName} (${userRole})`
      : `${user.firstName} ${user.lastName}`;
    return {
      label: label,
      userName: `${user.firstName} ${user.lastName}`,
      value: user.email
    };
  });
