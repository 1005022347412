import {
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut as signOutFirebase
} from 'firebase/auth';

import { UserCallback } from '../models/user';
import getAuthFirebase from '../auth/firebase.config';
import { AdminRole, DentistRole, DesignerRole, LabUserRole } from '../enum/user.ts';

const signIn = (email: string, password: string) => {
  return signInWithEmailAndPassword(getAuthFirebase(), email, password);
};

const signInWithUserCustomToken = (customToken: string) => {
  return signInWithCustomToken(getAuthFirebase(), customToken);
};

const signOut = () => {
  return signOutFirebase(getAuthFirebase());
};

const getAuthState = (callback: UserCallback) => {
  return onAuthStateChanged(getAuthFirebase(), (userFirebase) => {
    callback(userFirebase);
  });
};

const isCurrentUser = () => {
  return !!getAuthFirebase().currentUser;
};

const getClaims = async () => {
  return getAuthFirebase().currentUser?.getIdTokenResult();
};

const getUserRole = async (): Promise<
  undefined | DentistRole | AdminRole | LabUserRole | DesignerRole
> => {
  const idTokenResult = await getClaims();
  return idTokenResult?.claims?.role as
    | undefined
    | DentistRole
    | AdminRole
    | LabUserRole
    | DesignerRole;
};

const getToken = async (forceRefresh = false) => {
  return getAuthFirebase()?.currentUser?.getIdToken(forceRefresh);
};

/**
 * Force the refresh of Firebase token.
 * If we want to get the new claims, use getIdTokenResult() instead.
 */
const forceRefreshToken = () => {
  return getToken(true);
};

export {
  signIn,
  signInWithUserCustomToken,
  getAuthState,
  signOut,
  forceRefreshToken,
  getToken,
  isCurrentUser,
  getUserRole
};
