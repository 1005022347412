import { manufacturingStepDisplay } from '../../../../../enum/manufacturing-step.enum';
import i18next from 'i18next';
import {
  WorkflowComponentManufacturingStepEnum,
  WorkflowManufacturingStepEnum
} from '../../../../../enum/workflow-step';
import { isInManufacturingPendingStatus } from '../../../../../utils/order.utils';
import { ManufacturingComponentView } from '../../../../../models/component.tsx';

const manufacturingStepWithPendingKeys = Object.keys(WorkflowManufacturingStepEnum);

const isInProgressStep = (componentStep: WorkflowManufacturingStepEnum, displayStep: string) => {
  return (
    !isSuccessStep(componentStep, displayStep) &&
    manufacturingStepDisplay[displayStep].includes(componentStep)
  );
};

/**
 * Detect if the datagrid step is a current one compared with the component step.
 * If the previous datagrid steps are successfully ended and
 * the current datagrid step isn't the last one and not currently
 * with a loading step, the function returns true.
 * Otherwise, it returns false.
 *
 * @param componentStep
 * @param displayStep
 */
const isPendingStep = (componentStep: WorkflowManufacturingStepEnum, displayStep: string) => {
  const manufacturingStepDisplayKeys = Object.keys(manufacturingStepDisplay);
  const currentStepIndex = manufacturingStepDisplayKeys.indexOf(displayStep);
  const previousStep =
    currentStepIndex > 0 ? Object.keys(manufacturingStepDisplay)[currentStepIndex - 1] : undefined;
  const isItTheEnd =
    manufacturingStepWithPendingKeys.indexOf(componentStep) ===
    manufacturingStepWithPendingKeys.length - 1;
  return (
    previousStep &&
    !isInProgressStep(componentStep, displayStep) &&
    isSuccessStep(componentStep, previousStep) &&
    !isItTheEnd
  );
};

/**
 * Detect if a datagrid step is successfully ended when :
 * - the component step is the last one of the current datagrid step
 * - the component step corresponding to the next datagrid steps,
 * so the previous ones are marked as succeed.
 * @param componentStep
 * @param displayStep
 */
const isSuccessStep = (componentStep: WorkflowManufacturingStepEnum, displayStep: string) => {
  let isSuccess: boolean = false;
  if (
    manufacturingStepDisplay[displayStep].indexOf(componentStep) ===
    manufacturingStepDisplay[displayStep].length - 1
  ) {
    isSuccess = true;
  } else {
    const findComponentStep = Object.keys(manufacturingStepDisplay).find(
      (manufacturingStepDisplayKey) =>
        manufacturingStepDisplay[manufacturingStepDisplayKey].includes(componentStep)
    );

    if (findComponentStep) {
      isSuccess =
        Object.keys(manufacturingStepDisplay).indexOf(findComponentStep) >
        Object.keys(manufacturingStepDisplay).indexOf(displayStep);
    }
  }
  return isSuccess;
};

/**
 * Detect if the current component step has some previous steps.
 * In this case, we can rollback to them eventually and display a button to allow that.
 * @param componentStep
 */
const isStepBackExists = (componentStep: WorkflowManufacturingStepEnum) => {
  // Here sonarlint doesn't like if i only write :
  // manufacturingStepWithPendingKeys.indexOf(componentStep) > 0
  return (
    manufacturingStepWithPendingKeys.indexOf(componentStep) > -1 &&
    manufacturingStepWithPendingKeys.indexOf(componentStep) !== 0
  );
};

/**
 * Get the next component step to update it with an api call.
 * @param component
 */
const getNextStepEnum = (component: ManufacturingComponentView) => {
  let nextStep = manufacturingStepWithPendingKeys.indexOf(component.manufacturingStep) + 1;
  if (
    manufacturingStepWithPendingKeys.length > nextStep &&
    isInManufacturingPendingStatus({
      ...component,
      manufacturingStep: manufacturingStepWithPendingKeys[
        nextStep
      ] as WorkflowComponentManufacturingStepEnum
    })
  ) {
    nextStep++;
  }
  const nextStepKey =
    manufacturingStepWithPendingKeys.length > nextStep
      ? manufacturingStepWithPendingKeys[nextStep]
      : undefined;
  return (
    nextStepKey ??
    WorkflowManufacturingStepEnum[
      nextStepKey as unknown as keyof typeof WorkflowManufacturingStepEnum
    ]
  );
};

/**
 * Get the previous component step to update it with an api call.
 * @param componentStep
 */
const getPreviousStepEnum = (componentStep: WorkflowManufacturingStepEnum) => {
  const previousStep =
    Object.keys(WorkflowComponentManufacturingStepEnum).indexOf(componentStep) - 1;
  const previousStepKey =
    previousStep >= 0 ? manufacturingStepWithPendingKeys[previousStep] : undefined;
  return (
    previousStepKey ??
    WorkflowManufacturingStepEnum[
      previousStepKey as unknown as keyof typeof WorkflowManufacturingStepEnum
    ]
  );
};

/**
 * Get the label to display to call the patch api and update the component
 * with the next manufacturing step.
 * @param component
 */
const getActionNextStepLabel = (component: ManufacturingComponentView) => {
  const manufacturingStepKey = getNextStepEnum(component);
  return manufacturingStepKey
    ? i18next.t(
        `datagrid.manufacturingAction.${WorkflowManufacturingStepEnum[
          manufacturingStepKey as keyof typeof WorkflowManufacturingStepEnum
        ].toLowerCase()}`,
        {
          ns: 'production'
        }
      )
    : '';
};

/**
 * Check if the current step is equal to the last step
 * @param step
 */
const allIsSuccess = (step: WorkflowManufacturingStepEnum) => {
  const lastKeyStepDisplay =
    Object.keys(manufacturingStepDisplay)[Object.keys(manufacturingStepDisplay).length - 1];

  const lastKeyStep =
    manufacturingStepDisplay[lastKeyStepDisplay][
      manufacturingStepDisplay[lastKeyStepDisplay].length - 1
    ];
  return step === lastKeyStep;
};

export {
  isPendingStep,
  isStepBackExists,
  isInProgressStep,
  isSuccessStep,
  getActionNextStepLabel,
  getNextStepEnum,
  allIsSuccess,
  getPreviousStepEnum
};
