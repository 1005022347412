import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipContent } from '@platform-storybook/circlestorybook';

const PendingTooltip = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['common']);

  return (
    <Tooltip>
      <TooltipContent>{t('pendingElement.tooltipContent')}</TooltipContent>
      {children}
    </Tooltip>
  );
};

export default PendingTooltip;
