import { api } from './api';
import { Params } from '../models/datagrid';
import {
  ManufacturingComponentView,
  ManufacturingComponentPaginatedResponse
} from '../models/component';

const prefix_path = '/manufacturing/';

export const manufacturingOrdersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getManufacturingComponents: builder.query<ManufacturingComponentPaginatedResponse, Params>({
      query: (arg: Params) => {
        const { page, limit, filters, sort } = arg;
        return `${prefix_path}components?page=${page}&limit=${limit}${filters}${sort}`;
      }
    }),
    patchComponent: builder.mutation<
      ManufacturingComponentView,
      Partial<ManufacturingComponentView>
    >({
      query: ({ productId, id, ...patch }: Partial<ManufacturingComponentView>) => ({
        url: `${prefix_path}products/${productId}/components/${id}`,
        method: 'PATCH',
        body: patch
      })
    })
  })
});

export const { useLazyGetManufacturingComponentsQuery, usePatchComponentMutation } =
  manufacturingOrdersApi;
