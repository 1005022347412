import { useEffect, useState } from 'react';
import '@inovua/reactdatagrid-community/index.css';
import {
  Avatar,
  BodyContainer,
  Chips,
  DropdownMenu,
  Header,
  IconButton,
  Toast
} from '@platform-storybook/circlestorybook';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { authActions } from '../../store/auth/auth.reducers';
import { useTranslation } from 'react-i18next';
import './private-app.scss';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { api } from '../../services/api';
import { resetAll } from '../../store';
import { ColorPropsEnum } from '../../enum/color.enum';
import { ToastType } from '../../enum/feedback';
import { viteMode } from '../../utils/utils';
import {
  getAvatarMenuEntries,
  getCandyMenuEntries,
  getLeftMenuEntries
} from './private-app.utils.ts';
import { connectedUserInfoSelector } from '../../store/users/users.selectors.tsx';
import { UserInfo } from '../../models/user.tsx';
import { getPrivateAppPermissionsSelector } from '../../store/auth/permissions.selectors.tsx';
import { useLazyGetConnectedUserQuery } from '../../services/users-api.services.ts';

type props = {
  displayLayout: boolean;
};
const PrivateApp = ({ displayLayout = true }: props) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const [getConnectedUser] = useLazyGetConnectedUserQuery();
  const connectedUser: UserInfo | undefined = useAppSelector(connectedUserInfoSelector);
  const privateAppUserPermissions = useAppSelector(getPrivateAppPermissionsSelector);
  const toast = useAppSelector(toastSelector);
  const envLabel = viteMode();
  const [isResetStore, setIsResetStore] = useState<boolean>(false);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(api.util.resetApiState());
    setIsResetStore(true);
    getConnectedUser();
  }, []);

  const getAvatarLetter = () => {
    return connectedUser?.email?.substring(0, 1).toUpperCase();
  };

  return (
    isResetStore && (
      <>
        {toast && (
          <Toast
            message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
            onClose={() => {
              dispatch(feedbackActions.resetToast());
            }}
            autoHideDuration={
              toast.autoHideDuration ?? (toast.type === ToastType.DANGER ? 8000 : 6000)
            }
            type={toast.type}
            data-cy="global-feedback"
          />
        )}
        {displayLayout && (
          <Header logoClass="logo--circle" title={`Circle Lab`}>
            <>
              <div className="private-app__header__title__env">
                {envLabel && <Chips firstLabel={envLabel} color={ColorPropsEnum.WHITE} />}
              </div>
              <nav className="private-app__header__nav">
                <DropdownMenu
                  renderTargetButton={(props: { active: boolean }) => (
                    <IconButton
                      className="private-app__header__nav__icon-btn"
                      faIconClass="ellipsis-vertical"
                      mode="candy-menu"
                      isActive={props.active}
                      radius="full"
                      color={ColorPropsEnum.WHITE}
                    />
                  )}
                  data={getCandyMenuEntries()}
                />
                <DropdownMenu
                  renderTargetButton={(props: { active: boolean }) => (
                    <Avatar
                      label={getAvatarLetter() || ''}
                      isActive={props.active}
                      cursorPointer={true}
                      className="private-app__header__nav__avatar"
                    />
                  )}
                  data={getAvatarMenuEntries(privateAppUserPermissions, () => {
                    dispatch(authActions.logout());
                  })}
                />
              </nav>
            </>
          </Header>
        )}
        {displayLayout && (
          <BodyContainer
            menuData={getLeftMenuEntries(privateAppUserPermissions, connectedUser)}
            isMenuOpenedInitially={false}
            className="private-app__body">
            <Outlet />
          </BodyContainer>
        )}

        {!displayLayout && (
          <div className="private-app__viewer__body">
            <Outlet />
          </div>
        )}
      </>
    )
  );
};
export default PrivateApp;
