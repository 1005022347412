import React from 'react';
import { Provider } from 'react-redux';
import store from './store/index';
import './styles/_index.scss';
import { createRoot } from 'react-dom/client';
import './services/globalInterceptorSetup';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './i18n';

// Remove deprecated ReactDatagrid.
// Remove this part if our forked project contains a correction about DefaultProps deprecation.
if (process.env.NODE_ENV !== 'production') {
  const originalWarn = console.error;
  console.error = (...args) => {
    if (args.toString().includes('@inovua_reactdatagrid-enterprise.js')) {
      return;
    }
    originalWarn(...args);
  };
}
// endblock.

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);
