import { useEffect, useState } from 'react';
import { StringObject, UnknownObject } from '../models/common';
import { CheckboxItem, DropdownItem } from '../models/form';

/**
 *
 * @param initialValues
 * @param submitCallback
 * @param validateCallback give validation callback if you need custom validation (by default all field are required)
 * @returns
 */

const useForm = (
  initialValues: UnknownObject,
  submitCallback: () => void | Promise<void>,
  validateCallback?: (values: UnknownObject) => StringObject
) => {
  const [values, setValues] = useState<UnknownObject>(initialValues);
  const [errors, setErrors] = useState<StringObject>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (!Object.keys(errors).length && isSubmitting) {
      submitCallback();
    }
  }, [errors]);

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const resetValues = () => {
    setValues(initialValues);
  };

  // TEXTFIELD COMPONENT
  const handleChange = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    setValues((values: UnknownObject) => ({ ...values, [target.name]: target.value }));
  };

  const handleBlur = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    setErrors((errors: StringObject) => ({ ...errors, [target.name]: undefined }));
  };

  const resetValue = (valueToReset: string) => {
    setValues((values: UnknownObject) => ({
      ...values,
      [valueToReset]: initialValues[valueToReset]
    }));
  };

  const handleAdd = (valueToAdd: string, name: string, forceUpperCase = false) => {
    if (values[valueToAdd]) {
      const newValue = forceUpperCase
        ? (values[valueToAdd] as string).trim().toUpperCase()
        : (values[valueToAdd] as string).trim();
      if ((values[name] as string[]).includes(newValue)) {
        setErrors((errors: StringObject) => ({ ...errors, [valueToAdd]: 'alreadyExists' }));
      } else {
        const updatedValue = [...(values[name] as string[]), newValue];
        setValues((values: UnknownObject) => ({ ...values, [name]: updatedValue }));
        setErrors((errors: StringObject) => ({ ...errors, [valueToAdd]: undefined }));
      }
    }
  };

  // CHIP COMPONENT
  const handleDelete = (event: React.FormEvent, name: string) => {
    const target = (event.target as HTMLElement)?.closest('button')?.parentElement;
    const valueToDelete = target?.innerText.trim();
    const updatedValue = (values[name] as string[]).filter((value) => value !== valueToDelete);
    setValues((values: UnknownObject) => ({ ...values, [name]: updatedValue }));
  };

  // DROPDOWN & RADIOLIST COMPONENT
  const handleSelect = (newValue: string | number, name: string) => {
    setValues((values: UnknownObject) => ({ ...values, [name]: newValue }));
    setErrors((errors: StringObject) => ({ ...errors, [name]: undefined }));
  };

  // DROPDOWN MULTICHOICE COMPONENT
  const handleMultiSelect = (selectedValue: string | number, name: string) => {
    let updatedValues: Array<object>;
    if (selectedValue) {
      updatedValues = (values[name] as Array<Array<CheckboxItem>>).map(
        (data: Array<DropdownItem>) => {
          return data.map((data) => {
            if (data.value === selectedValue && !data.isDisabled) {
              return {
                ...data,
                isChecked: !data.isChecked
              };
            } else {
              return data;
            }
          });
        }
      );
    } else {
      updatedValues = (values[name] as Array<Array<CheckboxItem>>).map(
        (data: Array<DropdownItem>) =>
          data.map((data: DropdownItem) => ({ ...data, isChecked: false }))
      );
    }

    setValues((values: UnknownObject) => ({ ...values, [name]: updatedValues }));
    setErrors((errors: StringObject) => ({ ...errors, [name]: undefined }));
  };

  // CHECKBOX COMPONENT
  const handleCheck = (id: string | number, name: string) => {
    const updatedValues = (values[name] as Array<CheckboxItem>).map((val: CheckboxItem) => {
      if (val.value === id) {
        return {
          ...val,
          isChecked: !val.isChecked
        };
      } else {
        return val;
      }
    });
    setValues((values: UnknownObject) => ({ ...values, [name]: updatedValues }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    if (event) event.preventDefault();
    const newErrors = validateCallback ? validateCallback(values) : validateAllFieldsRequired();
    setErrors(newErrors);
    setIsSubmitting(true);
  };

  return {
    handleMultiSelect,
    handleChange,
    handleSubmit,
    handleAdd,
    handleDelete,
    handleBlur,
    handleSelect,
    handleCheck,
    resetValues,
    resetValue,
    values,
    errors
  };
};

export default useForm;
