import { useParams } from 'react-router-dom';
import { UserDetailsRefererEnum } from '../../../../enum/user';
import UserDetails from '../../../../features/user-details/UserDetails';

const DentistDetailPage = () => {
  const { email } = useParams();

  return email && <UserDetails email={email} referer={UserDetailsRefererEnum.USER_DETAILS} />;
};

export default DentistDetailPage;
