import DatagridFeature from '../../../../../features/datagrid/DatagridFeature';
import { rowClassname } from '../../../../../features/datagrid/columns';

const AssemblyPage = () => {
  return (
    <DatagridFeature
      key="assembly"
      style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
      dataSource={[]}
      rowHeight={46}
      columns={[]}
      rowClassName={rowClassname}
    />
  );
};

export default AssemblyPage;
