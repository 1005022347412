import {
  ComponentType,
  ComponentTypeProperties,
  ItemComponentProperties
} from '../../../../enum/component';
import { DropdownItem } from '../../../../models/form';
import i18next from 'i18next';
import {
  WorkflowModelingStepEnum,
  WorkflowPostModelingStepEnum
} from '../../../../enum/workflow-step';
import { FullProduct } from '../../../../models/product';
import { Angulation, Aspect, Material, Shade, Shape } from '../../../../models/common-types';
import { PositionKey } from '../../../../models/position.tsx';

/**
 * Converts WorkflowModelingStepEnum to an array of arrays of DropdownItem objects.
 * @returns {Array<Array<DropdownItem>>} - The steps as an array of arrays of DropdownItem objects.
 */
export const getWorkflowStepsForDropdown = (): Array<Array<DropdownItem>> => {
  return [
    Object.values({
      ...WorkflowModelingStepEnum,
      ...WorkflowPostModelingStepEnum
    })?.map((step) => {
      return {
        label: i18next.t(`order.steps.${step}`, {
          ns: 'common'
        }),
        value: step
      };
    })
  ];
};

/**
 * Get item components with specific property.
 *
 * @param {string} property - The specific property to add/update.
 * @param {number} id - The ID for the property.
 * @param code
 * @returns {OrderItemComponentLight[]} - An array of order item components with the specific property.
 */
export const buildItemComponentCustomizationValue = (
  property: string,
  id: number | string,
  code?: string
): { [key: string]: { id: number; code: string } | { id: number } | string | number } => {
  let propertyValue: { id: number; code: string } | { id: number } | string | number = id;
  if (typeof id === 'number') {
    propertyValue = code ? { id, code } : { id };
  }
  return { [property]: propertyValue };
};

/**
 * Check if the middle maxillary teeth are present in the current item.
 *
 * @param {Array<number>} positions - An array of tooth positions
 * @returns {boolean} - True if the middle maxillary teeth are present, false otherwise
 */
export const areMiddleMaxillaryTeethInCurrentItem = (positions: Array<number>): boolean => {
  return [11, 12, 13, 21, 22, 23].some((position) => positions.includes(position as PositionKey));
};

export const getMappings = (customizationKey: string) => {
  const mapping: {
    [key: string]: {
      componentType: ComponentType; //TOOTH FRAME
      componentTypeProperty: ComponentTypeProperties; // shades, materials
      itemComponentProperty: ItemComponentProperties; // shade, shape, material
    };
  } = {
    frameMaterial: {
      componentType: ComponentType.FRAME,
      componentTypeProperty: ComponentTypeProperties.MATERIALS,
      itemComponentProperty: ItemComponentProperties.MATERIAL
    },
    gingivaAspect: {
      componentType: ComponentType.GINGIVA,
      componentTypeProperty: ComponentTypeProperties.ASPECTS,
      itemComponentProperty: ItemComponentProperties.ASPECT
    },
    gingivaShade: {
      componentType: ComponentType.GINGIVA,
      componentTypeProperty: ComponentTypeProperties.SHADES,
      itemComponentProperty: ItemComponentProperties.SHADE
    },
    gingivaMaterial: {
      componentType: ComponentType.GINGIVA,
      componentTypeProperty: ComponentTypeProperties.MATERIALS,
      itemComponentProperty: ItemComponentProperties.MATERIAL
    },
    partialToothMaterial: {
      componentType: ComponentType.PARTIAL_TOOTH,
      componentTypeProperty: ComponentTypeProperties.MATERIALS,
      itemComponentProperty: ItemComponentProperties.MATERIAL
    },
    teethMaterial: {
      componentType: ComponentType.TOOTH,
      componentTypeProperty: ComponentTypeProperties.MATERIALS,
      itemComponentProperty: ItemComponentProperties.MATERIAL
    },
    teethShade: {
      componentType: ComponentType.TOOTH,
      componentTypeProperty: ComponentTypeProperties.SHADES,
      itemComponentProperty: ItemComponentProperties.SHADE
    },
    partialToothShade: {
      componentType: ComponentType.PARTIAL_TOOTH,
      componentTypeProperty: ComponentTypeProperties.SHADES,
      itemComponentProperty: ItemComponentProperties.SHADE
    },
    teethShape: {
      componentType: ComponentType.TOOTH,
      componentTypeProperty: ComponentTypeProperties.SHAPES,
      itemComponentProperty: ItemComponentProperties.SHAPE
    },
    toothStratificationTechnique: {
      componentType: ComponentType.TOOTH,
      componentTypeProperty: ComponentTypeProperties.TOOTH_STRATIFICATION_TECHNIQUES,
      itemComponentProperty: ItemComponentProperties.TOOTH_STRATIFICATION_TECHNIQUE
    }
  };

  if (Object.keys(mapping).includes(customizationKey)) {
    return mapping[customizationKey];
  }
  return null;
};

export const getCustomizationAttribute = (
  product: FullProduct,
  componentType: ComponentType,
  componentTypeProperties: Exclude<
    ComponentTypeProperties,
    ComponentTypeProperties.TOOTH_STRATIFICATION_TECHNIQUES
  >,
  id: number
): Shade | Shape | Angulation | Aspect | Material | undefined => {
  const component = product?.components?.find(
    (component) => component.componentType === componentType
  );
  return component?.[componentTypeProperties]?.find((attribute) => attribute.id === id);
};
