import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getDentalArchFromPositions,
  getOrderedRangesFromPositions
} from '../order-manager/teeth-map/utils';
import { faTooth } from '@fortawesome/free-solid-svg-icons';
import styles from './teeth-positions.module.scss';
import { Text } from '@platform-storybook/circlestorybook';
import { PositionKey } from '../../models/position';

type props = {
  teethPositions: PositionKey[];
};

export const TeethPositions = ({ teethPositions }: props) => {
  const buildTeethRanges = (teethPositions: PositionKey[]): string[] => {
    const result: string[] = [];
    const dentalArch = getDentalArchFromPositions(teethPositions);
    const teethPositionsSorted = getOrderedRangesFromPositions(teethPositions, dentalArch);

    teethPositionsSorted.forEach((range) => {
      if (range.length > 1) {
        result.push([range[0], range.slice(-1)].join('-'));
      }

      if (range.length === 1) {
        result.push(`${range[0]}`);
      }
    });

    return result;
  };
  return buildTeethRanges(teethPositions).map((range) => (
    <div className={styles['teeth-positions']} key={`pos-${range}`}>
      <FontAwesomeIcon icon={faTooth} className={styles['teeth-positions__icon']} />
      <Text data-cy={`position-${range}`} size="s" label={range} />
    </div>
  ));
};
