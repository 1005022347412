import { Box, Button, Checkbox, TextField } from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import styles from '../dentist-detail-page.module.scss';
import useForm from '../../../../../utils/useForm';
import { useTranslation } from 'react-i18next';
import { DentistInfos } from '../../../../../models/user';
import { dentistSelector } from '../../../../../store/users/users.selectors';
import { usersActions } from '../../../../../store/users/users.reducer';
import { CheckboxStringItem } from '../../../../../models/form';
import { ColorPropsEnum } from '../../../../../enum/color.enum';

type Props = {
  saveCallback: (user: Partial<DentistInfos>, email: string) => void;
  closeSideNavCallback: (opened: boolean) => void;
};

const IdentificationForm = ({ saveCallback, closeSideNavCallback }: Props) => {
  const { t } = useTranslation(['dentist']);

  const dispatch = useAppDispatch();
  const user = useAppSelector(dentistSelector);

  const onSubmit = () => {
    dispatch(
      usersActions.setDentist({
        ...user,
        firstName: values.firstName as string,
        lastName: values.lastName as string,
        phoneNumber: values.phoneNumber as string,
        isAllowedToOrder: (values.isAllowedToOrder as CheckboxStringItem[])[0].isChecked
      })
    );

    if (user?.email)
      saveCallback(
        {
          firstName: values.firstName as string,
          lastName: values.lastName as string,
          phoneNumber: values.phoneNumber as string,
          isAllowedToOrder: (values.isAllowedToOrder as CheckboxStringItem[])[0].isChecked
        },
        user.email
      );
    closeSideNavCallback(false);
  };

  const { values, errors, handleSubmit, handleBlur, handleChange, handleCheck } = useForm(
    {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNumber: user?.phoneNumber || '',
      isAllowedToOrder: [
        {
          value: 'isAllowedToOrder',
          isChecked: user?.isAllowedToOrder || false
        }
      ]
    },
    onSubmit
  );

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form
        onSubmit={handleSubmit}
        className={styles['dentist-detail-page__content__sidebar__form']}>
        <TextField
          id={'firstName'}
          name="firstName"
          label={t('createDentist.identification.firstName')}
          value={values.firstName}
          onBlur={handleBlur}
          type={'text'}
          onChange={handleChange}
          helperText={
            errors?.firstName ? t('createDentist.identification.firstNameMandatory') : undefined
          }
          variant={errors.firstName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder="John"
        />
        <TextField
          id={'lastName'}
          name="lastName"
          label={t('createDentist.identification.lastName')}
          value={values.lastName}
          onBlur={handleBlur}
          onChange={handleChange}
          type={'text'}
          helperText={
            errors?.lastName ? t('createDentist.identification.lastNameMandatory') : undefined
          }
          variant={errors.lastName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder="Smith"
        />
        <TextField
          label={t('createDentist.identification.phone')}
          id={'phone'}
          name="phoneNumber"
          value={values.phoneNumber}
          type={'tel'}
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={
            errors?.phoneNumber
              ? t('createDentist.identification.phoneMandatory')
              : t('createDentist.identification.helperText.phone')
          }
          variant={errors.phoneNumber ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('createDentist.identification.placeholder.phone')}
        />
        <Checkbox
          label={t('createDentist.establishment.userAllowedToOrder', { ns: 'dentist' })}
          isChecked={(values.isAllowedToOrder as CheckboxStringItem[])[0].isChecked}
          onClick={() => handleCheck('isAllowedToOrder', 'isAllowedToOrder')}
        />
        <div className="form__submit-button form__submit-button--right">
          <Button label={t('action.update', { ns: 'common' })} type="submit" />
        </div>
      </form>
    </Box>
  );
};
export default IdentificationForm;
