import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedbackReducer, FeedBack } from '../../models/feedback';

/**
 * https://gitlab.com/groups/anatoscope/circle/dev/platform/-/wikis/Functional-errors-management
 */

const initialFeedbackState: FeedbackReducer = {
  attentionBoxError: undefined,
  toast: undefined,
  fieldError: undefined
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: initialFeedbackState,
  reducers: {
    setAttentionBoxError: (state, action: PayloadAction<FeedBack>) => {
      state.attentionBoxError = action.payload;
    },
    setToast: (state, action: PayloadAction<FeedBack>) => {
      state.toast = action.payload;
    },
    resetAttentionBoxError: (state) => {
      state.attentionBoxError = undefined;
    },
    resetToast: (state) => {
      state.toast = undefined;
    },
    resetAllErrors: (state) => {
      state.attentionBoxError = undefined;
      state.toast = undefined;
      state.fieldError = undefined;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<FeedbackReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialFeedbackState };
    });
  }
});

const feedbackActions = feedbackSlice.actions;

export { feedbackActions, feedbackSlice };
