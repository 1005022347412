import i18next from 'i18next';
import { PrivateAppPermissions } from '../../models/connected-user.tsx';
import { UserInfo } from '../../models/user.tsx';
import { DesignerRole } from '../../enum/user.ts';

/**
 * Return main menu entries according to the connected user permissions
 * @param privateAppUserPermissions
 * @param connectedUser
 */
export const getLeftMenuEntries = (
  privateAppUserPermissions: PrivateAppPermissions,
  connectedUser: UserInfo | undefined
) => {
  const allowedEntries = [];

  if (privateAppUserPermissions.canAccessSupervision) {
    allowedEntries.push({
      label: i18next.t('link.dashboard', { ns: 'common' }),
      type: 'nav',
      link: '/dashboard',
      iconClass: 'fa-gauge'
    });
  }

  if (privateAppUserPermissions.canAccessProduction) {
    allowedEntries.push({
      label: i18next.t('link.production', { ns: 'common' }),
      type: 'nav',
      link: connectedUser?.role === DesignerRole.DESIGNER ? '/production/design' : '/production',
      iconClass: 'table-list'
    });
  }

  if (privateAppUserPermissions.canAccessDentists) {
    allowedEntries.push({
      label: i18next.t('link.dentists', { ns: 'common' }),
      type: 'nav',
      link: '/dentist',
      iconClass: 'fa-users'
    });
  }

  if (privateAppUserPermissions?.canAccessBilling) {
    allowedEntries.push({
      label: i18next.t('link.billing', { ns: 'common' }),
      type: 'nav',
      link: '/billing',
      iconClass: 'fa-receipt',
      disabled: true
    });
  }

  return [allowedEntries];
};

/**
 * Return candy menu entries
 */
export const getCandyMenuEntries = () => {
  return [
    [
      {
        label: i18next.t('appMenu.circleAppDentist', { ns: 'common' }),
        type: 'nav',
        link: 'https://alpha.circle.dental',
        target: '_blank'
      },
      {
        label: i18next.t('appMenu.circleAppAdmin', { ns: 'common' }),
        type: 'nav',
        link: 'https://dev.admin.circle.dental',
        target: '_blank'
      }
    ]
  ];
};

export const getAvatarMenuEntries = (
  privateAppUserPermissions: PrivateAppPermissions,
  onLogoutCallback: () => void
) => {
  const userRelatedEntries = [
    { label: i18next.t('link.myProfile', { ns: 'common' }), type: 'nav', link: '/profile' }
  ];

  if (privateAppUserPermissions.canAccessEstablishmentPreferences) {
    userRelatedEntries.push({
      label: i18next.t('link.myLaboratory', { ns: 'common' }),
      type: 'nav',
      link: '/preferences'
    });
  }

  const commonEntries = [
    {
      label: i18next.t('link.logout', { ns: 'common' }),
      type: 'button',
      onClick: () => onLogoutCallback()
    }
  ];

  return [userRelatedEntries, commonEntries];
};
