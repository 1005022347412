export enum FamilyColorEnum {
  FAMILY_FIXED = 'family-fixed',
  FAMILY_REMOV = 'family-remov',
  FAMILY_IMPLANT = 'family-implant',
  FAMILY_GUARDS = 'family-guards',
  FAMILY_OCR = 'family-ocr'
}

export enum SimpleColorsEnum {
  DEFAULT = 'default',
  WHITE = 'white',
  GREY = 'grey',
  BLACK = 'black',
  PRIMARY = 'primary',
  PURPLE = 'purple',
  PURPLE_MEDIUM = 'purple-medium',
  PURPLE_LIGHT = 'purple-light',
  PINK = 'pink',
  GREY_100 = 'grey-100'
}

enum StatusColorEnum {
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning'
}

enum ShadeColorEnum {
  A1 = 'a1',
  A2 = 'a2',
  A3 = 'a3',
  A3_5 = 'a3_5',
  A4 = 'a4',
  B1 = 'b1',
  B2 = 'b2',
  B3 = 'b3',
  B4 = 'b4',
  C1 = 'c1',
  C2 = 'c2',
  C3 = 'c3',
  C4 = 'c4',
  D2 = 'd2',
  D3 = 'd3',
  D4 = 'd4',
  T3 = 't3',
  T5 = 't5',
  T7 = 't7',
  METAL = 'metal'
}

export enum MaterialColorEnum {
  TITANIUM = 'titanium',
  RESIN = 'resin',
  PMMA = 'pmma',
  ZIRCONIUM = 'zirconium',
  COMPOSITE = 'composite',
  LITHIUM_DISILICATE = 'lithium-disilicate',
  CHROME_COBALT = 'chrome-cobalt',
  PEEK = 'peek',
  ACETAL = 'acetal',
  FIBER_COMPOSITE = 'fiber-composite',
  LAYERED_CERAL = 'layered-ceram'
}

export const ColorPropsEnum = {
  ...SimpleColorsEnum,
  ...FamilyColorEnum,
  ...StatusColorEnum,
  ...ShadeColorEnum,
  ...MaterialColorEnum
};

export type ColorPropsEnum = (typeof ColorPropsEnum)[keyof typeof ColorPropsEnum];
