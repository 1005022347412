import { DentalArchEnum } from '../../enum/component';
import { ToothSelectionEnum, ZoneLinkEnum } from '../../enum/map.enum';
import {
  notationToIndex,
  sortedLowerPositionsArray,
  sortedPositionsString,
  sortedUpperPositionsArray
} from '../../enum/position.enum';
import { PositionKeyString } from '../../models/position';
import { isUpperArch } from '../../features/order-manager/teeth-map/utils';
import {
  MapContext,
  Position,
  PositionsObj,
  ProductBubble,
  ProductCompatibilitiesContext
} from '../../models/map';
import { publicImagesUrl } from '../../utils/utils';
import { SimpleColorsEnum } from '../../enum/color.enum.ts';

/**
 * compute global range between missing teeth at the ends of the arch
 * @param {PositionsObj} positions
 * @param {DentalArchEnum} arch
 * @returns {Array<PositionKeyString>}
 */
const getRangeWithoutMissingAtTheEndOfTheArch = (
  positions: PositionsObj,
  arch: DentalArchEnum
): Array<PositionKeyString> => {
  const positionsArray = isUpperArch(arch)
    ? [...sortedUpperPositionsArray]
    : [...sortedLowerPositionsArray];

  let firstTooth = 0;
  let lastTooth = positionsArray.length - 1;

  // Find first non-missing tooth
  while (firstTooth <= lastTooth && positions[positionsArray[firstTooth]]?.missing) {
    firstTooth++;
  }

  // Find last non-missing tooth
  while (lastTooth >= firstTooth && positions[positionsArray[lastTooth]]?.missing) {
    lastTooth--;
  }

  // If all teeth are missing, firstTooth > lastTooth
  return firstTooth <= lastTooth ? positionsArray.slice(firstTooth, lastTooth + 1) : [];
};

/**
 * compute all available ranges between products on the map
 * @param {PositionsObj} positions
 * @param  {PositionKeyString[]} range
 * @returns {PositionKeyString[][]}
 */
const getAllAvailableRanges = (
  positions: PositionsObj,
  range: PositionKeyString[]
): PositionKeyString[][] => {
  let availableToothRange: Array<PositionKeyString> = [];
  const allAvailableRanges: Array<Array<PositionKeyString>> = [];
  range.forEach((position) => {
    if (positions[position]?.productIds.length === 0) {
      // No product on the tooth
      availableToothRange.push(position);
    } else {
      allAvailableRanges.push(availableToothRange);
      availableToothRange = [];
    }
  });
  if (availableToothRange.length > 0) {
    allAvailableRanges.push(availableToothRange);
  }
  return allAvailableRanges;
};

/**
 *
 * Computes forbidden positions on specific arch for a range or multi-range selection, before the first click on map
 * we do not consider missing teeth in the middle of the arc
 * checks if a missing tooth at the extremities could block range selection on an arch
 * checks for existing products on map
 *
 * @param {number} minTeeth
 * @param {PositionsObj} positions
 * @param {DentalArchEnum} arch
 * @returns {Array<PositionKeyString>}
 */
export const computeForbiddenPosMinTeeth = (
  minTeeth: number,
  positions: PositionsObj,
  arch: DentalArchEnum
): Array<PositionKeyString> => {
  const forbiddenPositions: Array<PositionKeyString> = [];

  // 1 - compute range without missing teeth at the extremities on specific arch
  const rangeWithoutMissingAtTheEnds: Array<PositionKeyString> =
    getRangeWithoutMissingAtTheEndOfTheArch(positions, arch);

  // 2 - in this global rangeWithoutMissingAtTheEnds, compute all available ranges without product
  const allAvailableRanges: Array<Array<PositionKeyString>> = getAllAvailableRanges(
    positions,
    rangeWithoutMissingAtTheEnds
  );

  // 3 - check if my product has enough space in each range
  allAvailableRanges.forEach((range) => {
    if (range.length >= minTeeth) {
      range.forEach((position, index) => {
        if (index + minTeeth > range.length && index + 1 - minTeeth < 0) {
          forbiddenPositions.push(position);
        }
      });
    } else {
      range.forEach((position) => {
        forbiddenPositions.push(position);
      });
    }
  });

  return forbiddenPositions;
};

/**
 * Compute forbidden positions for single-range BEFORE the first click according to min/max rule
 * @param {number} minTeeth
 * @param {PositionsObj} positions
 * @returns {Array<PositionKeyString>}
 */
export const computeInitForbiddenPosSingleRange = (
  minTeeth: number,
  positions: PositionsObj
): Array<PositionKeyString> => {
  const lowerForbiddenPositions = computeForbiddenPosMinTeeth(
    minTeeth || 0,
    positions,
    DentalArchEnum.LOWER
  );

  const upperForbiddenPositions = computeForbiddenPosMinTeeth(
    minTeeth || 0,
    positions,
    DentalArchEnum.UPPER
  );

  return [...lowerForbiddenPositions, ...upperForbiddenPositions];
};

/**
 * Compute forbidden positions for single-range AFTER the first click according to min/max rule
 * @param {PositionKeyString} selectedPosition
 * @param {number} minTeeth
 * @param {number} maxTeeth
 * @param {DentalArchEnum} currentArch
 * @returns {Array<PositionKeyString>}
 */
export const computeSingleRangeForbiddenPositions = (
  selectedPosition: PositionKeyString,
  minTeeth: number,
  maxTeeth: number,
  currentArch: DentalArchEnum
): Array<PositionKeyString> => {
  const forbiddenPositions: Array<PositionKeyString> = [];

  let archPositions = isUpperArch(currentArch)
    ? [...sortedUpperPositionsArray]
    : [...sortedLowerPositionsArray];

  // in order to facilitate the calculation min/max rules here below : reverse the positions array if the user started to click from right to left.
  // TODO : probalbly not working for small single range like bridge or ackers
  if (['2', '3'].includes(selectedPosition.charAt(0))) {
    archPositions = archPositions.reverse();
  }

  const firstToothIndex = archPositions.findIndex((position) => selectedPosition === position);

  archPositions.forEach((position: PositionKeyString, index: number) => {
    if (index + 1 < firstToothIndex + minTeeth) {
      forbiddenPositions.push(position);
    }

    if (index + 1 > firstToothIndex + maxTeeth) {
      forbiddenPositions.push(position);
    }
  });
  return forbiddenPositions;
};

/**
 *
 * @param {PositionsObj} positions
 * @param {DentalArchEnum} currentArch
 * @returns {Array<PositionKeyString>}
 */
export const getTeethBetweenZoneMultirange = (
  positions: PositionsObj,
  currentArch: DentalArchEnum
): Array<PositionKeyString> => {
  let startZone: number | null = null;
  let endZone: number | null = null;
  let nextStartZone: number | null = null;
  const forbiddenPositions: Array<PositionKeyString> = [];
  const archPositions = isUpperArch(currentArch)
    ? [...sortedUpperPositionsArray]
    : [...sortedLowerPositionsArray];
  archPositions.forEach((position: PositionKeyString, i: number) => {
    switch (positions[position]?.zone_link) {
      case ZoneLinkEnum.START:
        startZone = i;
        break;
      case ZoneLinkEnum.END:
        endZone = i;
        break;
      case ZoneLinkEnum.END_START:
        if (startZone) {
          endZone = i;
          nextStartZone = i;
        } else if (endZone) {
          startZone = i;
        }
        break;
    }

    if (startZone && endZone) {
      forbiddenPositions.push(...[...archPositions].slice(startZone + 1, endZone));
      startZone = nextStartZone || null;
      endZone = null;
    }
  });
  return forbiddenPositions;
};

/**
 * Return forbidden positions array depends on the rule allowSameProductOnArch
 * @param {PositionsObj} positions
 * @param {number} productId
 * @returns {Array<PositionKeyString>}
 */
export const computeForbiddenPositionsSameProductOnArch = (
  positions: PositionsObj,
  productId: number
) => {
  let forbiddenPositions: PositionKeyString[] = [];
  sortedUpperPositionsArray.every((positionKey: string) => {
    if (positions[positionKey].productIds.find((product) => product.productId === productId)) {
      forbiddenPositions = [...sortedUpperPositionsArray];
      return false;
    }
    return true;
  });

  sortedLowerPositionsArray.every((positionKey: string) => {
    if (positions[positionKey].productIds.find((product) => product.productId === productId)) {
      forbiddenPositions = [...forbiddenPositions, ...sortedLowerPositionsArray];
      return false;
    }
    return true;
  });

  return forbiddenPositions;
};

/**
 * Return Positions with selection tooth 'selected' 'unselectable' 'selectable'...
 * @param position
 * @param positions
 * @param productCompatibilities
 * @param forbiddenPositions
 * @returns PositionsObj
 */
export const computeSelectionToothPositions = (
  position: number,
  positions: PositionsObj,
  productCompatibilities: ProductCompatibilitiesContext,
  forbiddenPositions: PositionKeyString[]
): PositionsObj => {
  const newPositions = { ...positions };
  Object.keys(newPositions).forEach((positionKey: string) => {
    if (newPositions[positionKey].selection !== ToothSelectionEnum.SELECTED) {
      let productIdsOnPosition: number[] = [];
      if (newPositions[positionKey]?.productIds?.length > 0)
        productIdsOnPosition = newPositions[positionKey]?.productIds?.map(
          (productId) => productId.productId
        );

      const isMissingToothOnPosition: boolean = newPositions[positionKey].missing;
      const isSameProductOnPosition: boolean = productIdsOnPosition.includes(position);
      const notCompatibleProductOnPosition: boolean =
        productCompatibilities.notCompatibleToothProducts.some((id) =>
          productIdsOnPosition.includes(id)
        );
      if (
        // Not possible to select a tooth where there is missing
        isMissingToothOnPosition ||
        // Not possible to select a tooth where there is the same product
        isSameProductOnPosition ||
        // Not possible to select a tooth where there is a non compatible product on tooth
        notCompatibleProductOnPosition ||
        // Not possible to select a tooth which has a forbidden positions computed before
        [...new Set(forbiddenPositions)].includes(positionKey as PositionKeyString)
      ) {
        newPositions[positionKey] = {
          ...newPositions[positionKey],
          selection: ToothSelectionEnum.UNSELECTABLE
        };
      } else {
        newPositions[positionKey] = {
          ...newPositions[positionKey],
          selection: ToothSelectionEnum.SELECTABLE
        };
      }
    }
  });
  return newPositions;
};

/**
 * Return forbidden positions array for multirange, depends on product compatibility on tooth
 * @param positions
 * @param mapContext
 * @returns forbiddentPositions PositionKeyString[]
 */
export const computeMultiRangeForbiddenPosition = (
  positions: PositionsObj,
  mapContext: MapContext
): PositionKeyString[] => {
  const sortedPosition = isUpperArch(mapContext.activeArch)
    ? sortedUpperPositionsArray
    : sortedLowerPositionsArray;
  let forbiddenPositions: PositionKeyString[] = [];
  const leftPositionsStartValue = {} as { [key in PositionKeyString]: number };
  const rightPositionsStartValue = {} as { [key in PositionKeyString]: number };

  sortedPosition.forEach((pos) => {
    if (notationToIndex[pos] > notationToIndex[mapContext.start]) {
      rightPositionsStartValue[pos] = notationToIndex[pos];
    } else if (notationToIndex[pos] < notationToIndex[mapContext.start]) {
      leftPositionsStartValue[pos] = notationToIndex[pos];
    }
  });

  (Object.keys(rightPositionsStartValue) as PositionKeyString[])
    .sort((a: string, b: string) => (isUpperArch(mapContext.activeArch) ? +a - +b : +b - +a))
    .every((positionKey: PositionKeyString) => {
      if (positions[positionKey].productIds.length > 0) {
        return positions[positionKey].productIds.every((productId) => {
          if (
            mapContext.productCompatibilities.notCompatibleToothProducts.includes(
              productId.productId
            )
          ) {
            const rightRanges = computeRangeKeys(positionKey, sortedPosition.slice(-1)[0]);
            if (rightRanges && rightRanges?.length > 0) {
              forbiddenPositions = [...forbiddenPositions, ...rightRanges];
            }
            return false;
          }
          return true;
        });
      }
      return true;
    });

  (Object.keys(leftPositionsStartValue) as PositionKeyString[])
    .sort((a, b) => (isUpperArch(mapContext.activeArch) ? +b - +a : +a - +b))
    .every((positionKey: PositionKeyString) => {
      if (positions[positionKey].productIds.length > 0) {
        return positions[positionKey].productIds.every((productId) => {
          if (
            mapContext.productCompatibilities.notCompatibleToothProducts.includes(
              productId.productId
            )
          ) {
            const leftRanges = computeRangeKeys(sortedPosition[0], positionKey);
            if (leftRanges && leftRanges?.length > 0) {
              forbiddenPositions = [...forbiddenPositions, ...leftRanges];
            }
            return false;
          }
          return true;
        });
      }
      return true;
    });

  return forbiddenPositions;
};

/**
 * Computes positions to select between two limits/nound
 * @param {string} bound1 - tooth notation
 * @param {string} bound2 - tooth notation
 * @returns {Array<PositionKeyString>} - an array of teeth notation to select
 */
export const computeRangeKeys = (
  bound1: PositionKeyString | string,
  bound2: PositionKeyString | string
): Array<PositionKeyString> | undefined => {
  if (!bound1 || !bound2) {
    return undefined;
  }
  const min: number = Math.min(
    notationToIndex[bound1 as PositionKeyString],
    notationToIndex[bound2 as PositionKeyString]
  );
  const max: number = Math.max(
    notationToIndex[bound1 as PositionKeyString],
    notationToIndex[bound2 as PositionKeyString]
  );

  return Object.keys(notationToIndex).filter(
    (key) =>
      notationToIndex[key as PositionKeyString] >= min &&
      notationToIndex[key as PositionKeyString] <= max
  ) as Array<PositionKeyString>;
};

/**
 * Return Positions without zone links for active product
 * @param positions
 * @returns PositionsObj
 */
export const computeResetZoneLinkPos = (positions: { [key: string]: Position }): PositionsObj => {
  const newPositions = { ...positions };
  Object.keys(newPositions).forEach((positionKey) => {
    if (newPositions[positionKey].selection === ToothSelectionEnum.SELECTED) {
      newPositions[positionKey] = {
        ...newPositions[positionKey],
        zone_link: undefined
      };
    }
  });

  return newPositions;
};

/**
 * Groupe ranges in array for multirange [[11,12], [25]] by uniqueProductId and return Positions with zone_link
 * @param positions
 * @param uniqueProductId
 * @returns positions
 */
export const computeZoneLinkPosByPrd = (
  positions: PositionsObj,
  uniqueProductId: string
): PositionsObj => {
  const multiRangeNotations = [];
  let currentRange: PositionKeyString[] = [];

  sortedPositionsString.forEach((notation) => {
    const position = positions[notation];
    let isCurrentPrd = false;
    if (uniqueProductId) {
      isCurrentPrd = position.productIds
        .map((prd) => prd.uniqueProductId)
        .includes(uniqueProductId);
    }
    if (isCurrentPrd) {
      if (
        currentRange.length === 0 ||
        notationToIndex[notation] === notationToIndex[currentRange[currentRange.length - 1]] + 1
      ) {
        currentRange.push(notation); // Add notation to current range if consecutive
      } else {
        multiRangeNotations.push(currentRange); // Push current range to multiRangeNotations
        currentRange = [notation]; // Start a new range
      }
    }
  });

  if (currentRange.length > 0) {
    multiRangeNotations.push(currentRange); // Push the last range if not empty
  }

  const newPositions = { ...positions };

  multiRangeNotations.forEach((range, index) => {
    if (range.length === 1) {
      newPositions[range[0]].zone_link = ZoneLinkEnum.END_START;
    } else {
      if (index === 0) {
        newPositions[range[range.length - 1]].zone_link = ZoneLinkEnum.START;
      } else {
        newPositions[range[0]].zone_link = ZoneLinkEnum.END;

        newPositions[range[range.length - 1]].zone_link = ZoneLinkEnum.START;
      }
    }
  });
  return newPositions;
};

/**
 * Return Positions with zone links for active product
 * The difficulty here is adding the right zone_link according to the direction in which the multi-range was added
 * This is not a problem for items already added to the map
 * @param {PositionsObj} positions
 * @returns {PositionsObj}
 */
export const computeActivePrdZoneLinkPos = (positions: PositionsObj): PositionsObj => {
  const newPositions = { ...positions };
  let startArc: PositionKeyString | null = null;
  let currentArch: DentalArchEnum | undefined = undefined;

  sortedPositionsString.forEach((position: PositionKeyString, index) => {
    const currentPosition = newPositions[position];
    const prevPosition = newPositions[sortedPositionsString[index - 1]];
    const nextPosition = newPositions[sortedPositionsString[index + 1]];

    // If we are not on the same arch anymore, reset startArc
    if (currentPosition.arch !== currentArch) {
      startArc = null;
    }
    currentArch = currentPosition.arch;

    const isCurrentActive = currentPosition.selection === ToothSelectionEnum.SELECTED;
    const isNextNotSelectedTooth = nextPosition?.selection !== ToothSelectionEnum.SELECTED;
    const isPrevNotSelectedTooth = prevPosition?.selection !== ToothSelectionEnum.SELECTED;

    if (
      // link zone has been started, the current position is Active, the previous and next position is Selectable
      // OR an existing product starts and ends at this position
      // => single tooth where two links zone ends and begins
      startArc &&
      isCurrentActive &&
      isPrevNotSelectedTooth &&
      isNextNotSelectedTooth
    ) {
      currentPosition.zone_link = ZoneLinkEnum.END_START;
    } else if (
      // no link zone has been started, the current position is Active and the next position is Selectable
      // OR an existing product ends at this position
      // => begin the link zone
      startArc === null &&
      isCurrentActive &&
      isNextNotSelectedTooth
    ) {
      startArc = position;
      currentPosition.zone_link = ZoneLinkEnum.START;
    } else if (
      // link zone has been started, the current position is Active, the previous position is Selectable
      // OR an existing product starts at this position
      // => end the link zone
      startArc &&
      isCurrentActive &&
      isPrevNotSelectedTooth
    ) {
      currentPosition.zone_link = ZoneLinkEnum.END;
      startArc = null;
    }
  });
  return newPositions;
};

export const emptyBubble: ProductBubble = {
  type: 'product',
  size: 'small',
  backgroundColor: SimpleColorsEnum.WHITE,
  color: SimpleColorsEnum.GREY_100,
  url: `${publicImagesUrl}structures/EMPTY.svg`
};
