import { Navigate, Outlet } from 'react-router-dom';
import { checkTokenValidity } from '../utils/utils.tsx';
import { useAppDispatch } from '../store/hooks.tsx';

interface ProtectedRouteObj {
  isAllowed?: boolean;
  redirectPath: string;
  children: JSX.Element;
}

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/',
  children
}: ProtectedRouteObj): JSX.Element => {
  const dispatch = useAppDispatch();

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  checkTokenValidity(dispatch);
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
