import { AdminRole, DesignerRole, LabUserRole } from '../../enum/user.ts';
import {
  ControlTabPermissions,
  DentistManagementPermissions,
  DesignTabPermissions,
  LabDetailsUserPermissions,
  ManufacturingTabPermissions,
  OrderDetailPagePermissions,
  PrivateAppPermissions,
  ProductionPagePermissions,
  ShippingTabPermissions,
  SupervisionTabPermissionsType,
  ValidationTabPermissionsType
} from '../../models/connected-user.tsx';
import { ReducerState } from './auth.selectors.tsx';
import { createSelector } from '@reduxjs/toolkit';

const selectConnectedUser = (state: ReducerState) => state.authenticationState.connectedUser;

// todo : supprimer les droits pour les administrateurs qui ne devraient pas être autorisés à se connecter sur lab (mais sur admin)
// ils ont été conservés pour les tests le temps que le demockage soit en place https://gitlab.com/anatoscope/circle/dev/platform/tech-workshop/-/issues/89#firebase-

const getPrivateAppPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): PrivateAppPermissions => {
    return {
      canAccessSupervision: connectedUser?.role !== DesignerRole.DESIGNER,
      canAccessProduction: !!connectedUser,
      canAccessDentists: connectedUser?.role !== DesignerRole.DESIGNER,
      canAccessBilling:
        connectedUser?.role === AdminRole.ADMIN || connectedUser?.role === LabUserRole.SUPERVISOR,
      canAccessEstablishmentPreferences: connectedUser?.role !== DesignerRole.DESIGNER
    };
  }
);

const getSupervisionTabPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): SupervisionTabPermissionsType => {
    return {
      canCreateOrEditOrder:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST,
      canAdministrateOrder:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST,
      canPerformSupervisionActions:
        connectedUser?.role === AdminRole.ADMIN || connectedUser?.role === LabUserRole.SUPERVISOR
    };
  }
);

const getDentistManagementPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): DentistManagementPermissions => {
    return {
      canPerformDentistManagementActions:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST,
      canViewDentistInfo: connectedUser?.role !== DesignerRole.DESIGNER
    };
  }
);

const getOrderDetailsPagePermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): OrderDetailPagePermissions => {
    return {
      canViewDetail: !!connectedUser,
      canViewComments: !!connectedUser,
      canViewHistory: connectedUser?.role !== DesignerRole.DESIGNER,
      canEditOrder:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST,
      canAdministrateOrder:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST,
      canViewDentistInfo: connectedUser?.role !== DesignerRole.DESIGNER
    };
  }
);

const getProductionPagePermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): ProductionPagePermissions => {
    return {
      canAccessValidationTab: connectedUser?.role !== DesignerRole.DESIGNER,
      canAccessModelingTab: !!connectedUser,
      canAccessManufacturingTab: connectedUser?.role !== DesignerRole.DESIGNER,
      canAccessAssemblyTab: connectedUser?.role !== DesignerRole.DESIGNER,
      canAccessControlTab: connectedUser?.role !== DesignerRole.DESIGNER,
      canAccessDeliveryTab: connectedUser?.role !== DesignerRole.DESIGNER
    };
  }
);

const getValidationTabPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): ValidationTabPermissionsType => {
    return {
      canPerformValidationActions:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST,
      canEditOrder:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST,
      canAdministrateOrder:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST
    };
  }
);

const getControlTabPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): ControlTabPermissions => {
    return {
      canPerformControlActions:
        connectedUser?.role === LabUserRole.SUPERVISOR || connectedUser?.role === AdminRole.ADMIN
    };
  }
);

const getDesignTabPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): DesignTabPermissions => {
    return {
      canPerformDesignActions:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST ||
        connectedUser?.role === LabUserRole.LAB_DESIGNER ||
        connectedUser?.role === DesignerRole.DESIGNER,
      canEditOrder:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST,
      canAdministrateOrder:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST,
      // This needs the connected user to be fetched from ms-manufacture
      canDesignWithBeta: false
    };
  }
);

const getManufacturingTabPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): ManufacturingTabPermissions => {
    return {
      canPerformManufacturingActions:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST ||
        connectedUser?.role === LabUserRole.TECHNICIAN
    };
  }
);

const getShippingTabPermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): ShippingTabPermissions => {
    return {
      canPerformShippingActions:
        connectedUser?.role === LabUserRole.SUPERVISOR || connectedUser?.role === AdminRole.ADMIN
    };
  }
);

const getLabDetailsPagePermissions = createSelector(
  [selectConnectedUser],
  (connectedUser): LabDetailsUserPermissions => {
    return {
      canPerformLabManagementActions:
        connectedUser?.role === AdminRole.ADMIN ||
        connectedUser?.role === LabUserRole.SUPERVISOR ||
        connectedUser?.role === LabUserRole.PROSTHETIST
    };
  }
);

export {
  getPrivateAppPermissionsSelector,
  getSupervisionTabPermissionsSelector,
  getDentistManagementPermissionsSelector,
  getOrderDetailsPagePermissionsSelector,
  getProductionPagePermissionsSelector,
  getValidationTabPermissionsSelector,
  getControlTabPermissionsSelector,
  getDesignTabPermissionsSelector,
  getManufacturingTabPermissionsSelector,
  getShippingTabPermissionsSelector,
  getLabDetailsPagePermissions
};
