import i18next from 'i18next';
import { DentalNotation, UserDetailsRefererEnum } from '../../enum/user';
import { UserInfo } from '../../models/user';

const computeDentalNotationTranslation = (user?: UserInfo) => {
  let dentalNotation = i18next.t('detailDentist.unknown', { ns: 'dentist' });

  if (user && Object.values(DentalNotation).includes(user.dentalNotation)) {
    dentalNotation =
      i18next.t(`createDentist.customization.${user.dentalNotation.toLowerCase()}.label`, {
        ns: 'dentist'
      }) +
      ' (' +
      i18next.t(`createDentist.customization.${user.dentalNotation.toLowerCase()}.helperText`, {
        ns: 'dentist'
      }) +
      ')';
  }

  return dentalNotation;
};

export type UserDetailsCustomizedTranslations = {
  dentalNotation: string;
  resetPasswordBtnLabel: string;
  resetPasswordDialogTitle: string;
};

const getMyProfileTranslations = (user: UserInfo) => {
  return {
    dentalNotation: computeDentalNotationTranslation(user),
    resetPasswordBtnLabel: i18next.t('detailDentist.resetMyPassword', { ns: 'dentist' }),
    resetPasswordDialogTitle: i18next.t('dialog.resetMyPassword', { ns: 'dentist' })
  } as UserDetailsCustomizedTranslations;
};

const getUserDetailsTranslations = (user: UserInfo) => {
  return {
    dentalNotation: computeDentalNotationTranslation(user),
    resetPasswordBtnLabel: i18next.t('detailDentist.resetPassword', { ns: 'dentist' }),
    resetPasswordDialogTitle: i18next.t('dialog.resetPassword.title', {
      ns: 'dentist',
      user: `${user?.firstName} ${user?.lastName}`
    })
  } as UserDetailsCustomizedTranslations;
};

export const getCustomizedTranslations = (user: UserInfo, referer: UserDetailsRefererEnum) => {
  return referer === UserDetailsRefererEnum.USER_DETAILS
    ? getUserDetailsTranslations(user)
    : getMyProfileTranslations(user);
};
