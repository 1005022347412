import {
  commentsOrderAction,
  editAction,
  toggleManufactureAction
} from '../../../../../features/datagrid/menu-entry';
import { TypeColWithNamePropertyPlatform } from '../../../../../models/datagrid';
import {
  Button,
  DropdownMenu,
  IconButton,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import {
  ActionBtn,
  colAssignee,
  colDate,
  colDentistName,
  colFamilies,
  colInstructions,
  colOrderNumber,
  colOrderProducts,
  colStatus
} from '../../../../../features/datagrid/columns';
import i18next from 'i18next';
import { Order } from '../../../../../models/order';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';
import { getColumnOptions } from '../../../../../features/datagrid/datagrid.utils';
import { ManufacturingComponentView } from '../../../../../models/component.tsx';
import { isInPendingStatus } from '../../../../../utils/order.utils.ts';
import { ValidationTabPermissionsType } from '../../../../../models/connected-user.tsx';
import { CommonTypes } from '../../../../../models/common-types.tsx';
import { AppDispatch } from '../../../../../store';

export const colValidationMenu = <T extends Order | ManufacturingOrder>(
  connectedUserPermissions: ValidationTabPermissionsType,
  handleToggleManufactureCallback: (data: T) => Promise<void>,
  handleCommentCallback: (data: T) => void
): TypeColWithNamePropertyPlatform | undefined => {
  // Do not display the column if user cannot perform any action
  if (
    !connectedUserPermissions.canEditOrder &&
    !connectedUserPermissions.canAdministrateOrder &&
    !connectedUserPermissions.canPerformValidationActions
  ) {
    return;
  }

  return {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      const availableActionsForUser = [];

      if (data && connectedUserPermissions.canEditOrder) {
        availableActionsForUser.push([editAction]);
      }

      if (data && connectedUserPermissions.canEditOrder) {
        availableActionsForUser.push([
          toggleManufactureAction(data, handleToggleManufactureCallback)
        ]);
      }

      if (data && connectedUserPermissions.canPerformValidationActions) {
        availableActionsForUser.push([commentsOrderAction(data, handleCommentCallback)]);
      }

      const kebabMenu = (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton
              data-cy="datagrid-menu"
              faIconClass="ellipsis-vertical"
              isActive={active}
              isDisabled={isInPendingStatus(data.currentStep)}
              radius="full"
            />
          )}
          data={availableActionsForUser}
        />
      );
      if (isInPendingStatus(data.currentStep)) {
        return (
          <Tooltip>
            <TooltipContent>
              {i18next.t('pendingElement.tooltipContent', { ns: 'common' })}
            </TooltipContent>
            {kebabMenu}
          </Tooltip>
        );
      } else {
        return kebabMenu;
      }
    }
  };
};

export const colReviewAction = <T extends Order | ManufacturingOrder | ManufacturingComponentView>(
  actionBtn: ActionBtn<T>
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'validationAction',
      i18next.t('datagrid.columns.validation', { ns: 'production' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    render: ({ data }) => {
      const displayedButton = (
        <Button
          label={actionBtn.label}
          size="s"
          onClick={() => actionBtn.onClick(data)}
          isDisabled={isInPendingStatus(data.currentStep)}
        />
      );
      if (isInPendingStatus(data.currentStep)) {
        return (
          <Tooltip>
            <TooltipContent>
              {i18next.t('pendingElement.tooltipContent', { ns: 'common' })}
            </TooltipContent>
            {displayedButton}
          </Tooltip>
        );
      } else {
        return displayedButton;
      }
    }
  };
};

export const getDatagridColumns = (
  connectedUserPermissions: ValidationTabPermissionsType,
  dispatch: AppDispatch,
  commentActionBtn: ActionBtn<Order>,
  validateActionBtn: ActionBtn<Order>,
  commonTypes: CommonTypes,
  handleToggleManufactureCallback: (order: Order) => Promise<void>,
  handleClickComment: (order: Order) => void
): TypeColWithNamePropertyPlatform[] => {
  const allColumns = [
    colStatus,
    colOrderNumber(dispatch, commentActionBtn),
    colDentistName,
    colAssignee(connectedUserPermissions.canPerformValidationActions),
    colFamilies(commonTypes),
    colOrderProducts,
    colInstructions,
    colDate('limitShippingDate'),
    colValidationMenu(connectedUserPermissions, handleToggleManufactureCallback, handleClickComment)
  ].filter((column) => column !== undefined) as TypeColWithNamePropertyPlatform[];

  // Add review order column if user is allowed to
  if (connectedUserPermissions.canPerformValidationActions) {
    allColumns.splice(4, 0, colReviewAction(validateActionBtn));
  }

  // All undefined columns have been filtered
  return allColumns as TypeColWithNamePropertyPlatform[];
};
