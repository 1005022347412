import { useState } from 'react';
import { Wizard, BreadcrumbsBar, BreadcrumbItem } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import styles from './create-dentist-page.module.scss';
import { useNavigate } from 'react-router-dom';
import { WizardStep } from '../../../../models/navigation';
import IdentificationForm from './identification-form/IdentificationForm';
import CustomizationForm from './customization-form/CustomizationForm';
import EstablishmentForm from './establishment-form/EstablishmentForm';
import { useAppDispatch } from '../../../../store/hooks';
import { usersActions } from '../../../../store/users/users.reducer';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../enum/feedback';

const CreateDentistPage = () => {
  const { t } = useTranslation(['dentist']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedStep, setSelectedStep] = useState<number>(0);
  const [steps] = useState<WizardStep[]>([
    { id: 0, title: t('createDentist.wizard.identification') },
    { id: 1, title: t('createDentist.wizard.customization') },
    { id: 2, title: t('createDentist.wizard.establishment') }
  ]);

  const handlePrevious = () => {
    setSelectedStep(selectedStep - 1);
  };

  const handleSubmit = () => {
    dispatch(usersActions.resetDentist());
    navigate('/dentist');
    dispatch(
      feedbackActions.setToast({
        message: t('toast.dentistInvited', { ns: 'dentist' }),
        type: ToastType.SUCCESS
      })
    );
  };

  const navigateNextStep = () => {
    setSelectedStep(selectedStep + 1);
  };

  return (
    <div>
      <div className={styles['create-dentist-page__header']}>
        <BreadcrumbsBar type={'navigation'}>
          <BreadcrumbItem
            text={t('tabs.dentists', { ns: 'dentist' })}
            link="/dentist"></BreadcrumbItem>
          <BreadcrumbItem
            text={t('createDentist.title', { ns: 'dentist' })}
            link="dentist/create"></BreadcrumbItem>
        </BreadcrumbsBar>
      </div>
      <div className={styles['create-dentist-page__main']}>
        <Wizard
          options={steps}
          selectedIndex={selectedStep}
          displayedInRow={false}
          className={styles['create-dentist-page__wizard']}
        />
        {selectedStep === 0 && <IdentificationForm nextCallback={navigateNextStep} />}
        {selectedStep === 1 && (
          <CustomizationForm nextCallback={navigateNextStep} previousCallback={handlePrevious} />
        )}
        {selectedStep === 2 && (
          <EstablishmentForm submitCallback={handleSubmit} previousCallback={handlePrevious} />
        )}
      </div>
    </div>
  );
};

export default CreateDentistPage;
