import { useEffect, useState } from 'react';
import { FilesList, FileUpload, Viewer } from '@platform-storybook/circlestorybook';
import { OrderFile } from '../../../models/order';
import styles from './file-manager.module.scss';
import { useTranslation } from 'react-i18next';
import { FileLabelEnum } from '../../../enum/file-label';
import { FileManagerOrientationProp } from '../file-manager.enum';
import { FileManagerProps, labelsListProps } from '../file-manager.model';
import { useAppSelector } from '../../../store/hooks';
import { isLoadingFilesSelector } from '../../../store/orders/orders.selectors';

const FileManager = ({
  orientation = FileManagerOrientationProp.LANDSCAPE,
  filesList = [],
  isReadOnly = false,
  height,
  displayLabel = true,
  isViewerLoading = false,
  file3DToDisplay,
  fileTextureToDisplay,
  fileImageToDisplay,
  onUploadFileCallback,
  onClickFileCallback,
  onLabelChangeCallback,
  fileActions,
  labelsList,
  className,
  isModal = false
}: FileManagerProps & labelsListProps) => {
  const { t } = useTranslation(['fileManager']);
  const isLoadingFiles = useAppSelector(isLoadingFilesSelector);
  const [blobImage, setBlobImage] = useState<string>();

  const orientationClass = styles[`file-manager--${orientation}`];

  const getLabelViewer = (): string => {
    if (!filesList || filesList.length === 0) {
      return t('noFile');
    }
    return t('vizualizationError');
  };

  /**
   * When a fileImageToDisplay is set and the value change,
   * we load the File object and open a stream to fill the src img element HTML attribute.
   */
  useEffect(() => {
    if (fileImageToDisplay) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        setBlobImage(e.target?.result as string);
      };
      reader.readAsDataURL(fileImageToDisplay.data as Blob);
    }
  }, [fileImageToDisplay]);

  return (
    <div className={[styles['file-manager'], orientationClass, className].join(' ')}>
      {!fileImageToDisplay && (
        <Viewer
          noFileLabel={getLabelViewer()}
          file3D={file3DToDisplay}
          fileTexture={fileTextureToDisplay}
          isLoading={isViewerLoading}
          className={styles['file-manager__viewer']}
          data-cy="files-viewer"
        />
      )}
      {fileImageToDisplay && (
        <div className={styles['file-manager__viewer__image__container']}>
          <img
            className={styles['file-manager__viewer__image__content']}
            src={blobImage}
            alt={fileImageToDisplay.fileName}
            style={{ maxHeight: height }}
          />
        </div>
      )}
      <div className={styles['file-manager__list']}>
        {onUploadFileCallback && (
          <FileUpload
            data-cy="file-upload"
            className={[
              styles[
                `file-manager__list__upload--${filesList.length > 0 ? 'default' : 'full'}-height`
              ],
              isModal ? styles['file-manager__list__upload__modal--full-height'] : '',
              isLoadingFiles ? styles['file-manager--disabled'] : ''
            ].join(' ')}
            title={t('uploadBox')}
            displayedInRow={orientation === FileManagerOrientationProp.PORTRAIT}
            onUploadFile={onUploadFileCallback}
          />
        )}
        {filesList.length > 0 && (
          <FilesList
            files={filesList}
            fileActions={fileActions}
            labelerProps={{
              coverText: t('actions.updateLabel'),
              isLoading: false,
              onChange: (file: OrderFile, label: FileLabelEnum) => {
                if (onLabelChangeCallback) onLabelChangeCallback(file, label);
              },
              maxHeight: 350
            }}
            readonly={isReadOnly}
            style={{ height: height }}
            labelsList={displayLabel ? labelsList : undefined}
            initialSelectedIndex={filesList.length - 1}
            className={styles['file-manager__list__files']}
            onClickCallback={(selectedFile: OrderFile) => {
              if (onClickFileCallback) onClickFileCallback(selectedFile);
            }}
            data-cy="files-list"
          />
        )}
      </div>
    </div>
  );
};
export default FileManager;
