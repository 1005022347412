import { useEffect } from 'react';
import styles from './assign-order-form.module.scss';
import {
  AttentionBox,
  Box,
  Button,
  Dropdown,
  HorizontalBarChart,
  Skeleton
} from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { attentionBoxErrorSelector } from '../../../../store/feedback/feedback.selector';
import { getMessageError, getNameFromEmail } from '../../../../utils/utils';
import { useGetLabUsersQuery } from '../../../../services/users-api.services';
import useForm from '../../../../utils/useForm';
import {
  useGetOrdersIndicatorsQuery,
  usePatchOrderMutation
} from '../../../../services/orders-api.services';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { LabUser } from '../../../../models/user';
import { getLabUsersListForDropdownSelector } from '../../../../store/users/users.selectors';
import { usePatchManufacturingOrderMutation } from '../../../../services/manufacturing-orders-api.services';
import { WorkFlowStepPreModelingEnum } from '../../../../enum/workflow-step';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import { ToastType } from '../../../../enum/feedback';

type props = {
  onSubmitAssignOrderCallback: () => void;
  orderNumber: string;
};

const AssignOrderForm = ({ onSubmitAssignOrderCallback, orderNumber }: props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);
  const labUsersListForDropdown = useAppSelector(getLabUsersListForDropdownSelector());

  const {
    data: ordersIndicators,
    isLoading: isLoadingOrderIndicators,
    isError: isErrorIndicators
  } = useGetOrdersIndicatorsQuery('OrdersIndicators');

  const [
    patchOrder,
    { data: patchedOrder, isLoading: isLoadingPatchOrder, isSuccess: isPatchedOrder, error, reset }
  ] = usePatchOrderMutation();

  const [patchManufacturingOrder] = usePatchManufacturingOrderMutation();
  const { data: labUsers, isLoading: isLoadingLabUsers } = useGetLabUsersQuery();

  const assigneeMandatory = i18next.t('assignOrderModal.assigneeMandatory', { ns: 'dashboard' });

  const assigneesChart =
    labUsers?.length && !isErrorIndicators
      ? labUsers.map((user: LabUser) => {
          const assignee = ordersIndicators?.assignees.find(
            (assignee) => assignee.assigneeEmail === user.email
          );
          return {
            id: user.id,
            label: `${user.firstName} ${user.lastName}`,
            value: assignee ? assignee.orderCount : 0
          };
        })
      : [];

  useEffect(() => {
    if (isPatchedOrder) {
      if (
        !Object.values(WorkFlowStepPreModelingEnum).includes(
          patchedOrder.currentStep as WorkFlowStepPreModelingEnum
        )
      ) {
        patchManufacturingOrder({
          orderNumber: patchedOrder.orderNumber,
          assigneeEmail: values.assignee as string,
          assigneeName: getNameFromEmail(labUsers, values.assignee as string)
        });
      }
      dispatch(
        feedbackActions.setToast({
          message: t('assignOrderModal.success', { ns: 'dashboard' }),
          type: ToastType.SUCCESS
        })
      );
      onSubmitAssignOrderCallback();
    }
  }, [isPatchedOrder]);

  const onSubmit = async () => {
    patchOrder({
      orderNumber: orderNumber,
      assigneeEmail: values.assignee as string,
      assigneeName: getNameFromEmail(labUsers, values.assignee as string)
    });
  };

  const { values, errors, handleSubmit, handleSelect } = useForm(
    {
      assignee: null
    },
    onSubmit
  );

  return (
    <>
      {error && attentionBoxError ? (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={getMessageError(error)}
          className={styles['assign-order-form__attention-box']}
          onClose={() => reset()}
        />
      ) : (
        ''
      )}

      {isLoadingOrderIndicators || isLoadingLabUsers ? (
        <>
          <Skeleton type="text" className="skeleton" width="90%" />
          <Skeleton type="text" className="skeleton" width="90%" />
          <Skeleton type="text" className="skeleton" width="90%" />
        </>
      ) : (
        <HorizontalBarChart
          data={assigneesChart}
          title={t('assignOrderModal.chart.title', { ns: 'dashboard' })}
          className={styles['assign-order-form__chart']}
        />
      )}
      <Box color={ColorPropsEnum.WHITE} className={styles['assign-order-form__form']}>
        <form onSubmit={handleSubmit}>
          <Dropdown
            label={t('assignOrderModal.label', { ns: 'dashboard' })}
            data={labUsersListForDropdown}
            value={values?.assignee}
            size="m"
            placeholder={t('assignOrderModal.placeholder', { ns: 'dashboard' })}
            onChange={(newValue: string) => handleSelect(newValue, 'assignee')}
            helperText={errors?.assignee && assigneeMandatory}
            variant={errors?.assignee ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            isLoading={isLoadingLabUsers}
          />
          <div className="form__submit-button form__submit-button--right">
            <Button
              label={t('assignOrderModal.action', { ns: 'dashboard' })}
              isLoading={isLoadingPatchOrder}
              type="submit"
            />
          </div>
        </form>
      </Box>
    </>
  );
};

export default AssignOrderForm;
