import { ProductCategory } from '../../enum/product.enum';
import {
  OrderFile,
  OrderItem,
  OrderItemComponentForCreation,
  OrderItemForCreation,
  OrderItemLight
} from '../../models/order';

/**
 * Filter a list of order items to keep only the ones to be created during edit
 *
 * @param {(OrderItemLight | OrderItem)[]} itemsState - The current state of items
 * @returns {(OrderItemLight | OrderItem)[]} - Filtered array containing all items to be created
 */
export const getOrderItemsToCreate = (
  itemsState: (OrderItemLight | OrderItem)[] // current
): (OrderItemLight | OrderItem)[] => {
  const itemExistsInOrder = (item: OrderItemLight | OrderItem): boolean => {
    return (item as OrderItem).id !== undefined;
  };
  return itemsState.filter((item) => !itemExistsInOrder(item));
};

/**
 * Filter a list of order items to keep only the ones to be deleted during edit
 *
 * @param {(OrderItemLight | OrderItem)[]} previousItemsState - The initial state of items before editing
 * @param {(OrderItemLight | OrderItem)[]} currentItemsState - The current state of items after editing
 * @returns {(OrderItemLight | OrderItem)[]} - Filtered array containing all items to be deleted
 */
export const getOrderItemsToDelete = (
  previousItemsState: (OrderItemLight | OrderItem)[],
  currentItemsState: (OrderItemLight | OrderItem)[]
): (OrderItemLight | OrderItem)[] => {
  const itemExistsInOrder = (item: OrderItemLight | OrderItem): boolean => {
    return (item as OrderItem).id !== undefined;
  };
  const currentItemsIds = currentItemsState
    .filter(itemExistsInOrder)
    .map((item: OrderItem | OrderItemLight) => (item as OrderItem).id);
  return previousItemsState.filter(
    (previousItem) => !currentItemsIds.includes((previousItem as OrderItem).id)
  );
};

/**
 * Filter a list of files to keep only the ones to be uploaded during edit
 *
 * @param {OrderFile[]} filesState - The current state of files
 * @returns {OrderFile[]} - Filtered array containing all files to be uploaded
 */
export const getOrderFilesToUpload = (
  filesState: OrderFile[] // current
): OrderFile[] => {
  return filesState.filter((file) => !file.id);
};

/**
 * Filter a list of files to keep only the ones to be deleted during edit
 *
 * @param {OrderFile[]} currentFilesState - The initial state of files before editing
 * @param {OrderFile[]} previousFilesState - The current state of files after editing
 * @returns {OrderFile[]} - Filtered array containing all files to be deleted
 */
export const getOrderFilesToDelete = (
  currentFilesState: OrderFile[],
  previousFilesState?: OrderFile[]
): OrderFile[] => {
  const currentFilesIds = currentFilesState.map((file) => file.id);
  return previousFilesState
    ? previousFilesState?.filter((previousFile) => !currentFilesIds.includes(previousFile.id))
    : [];
};

/**
 * Determines if a given product category is considered as provisional.
 *
 * @param {ProductCategory} category - The product category to check.
 * @returns {boolean} - True if the category is provisional, false otherwise.
 */
export const isCategoryProvisional = (category: ProductCategory): boolean => {
  return category === ProductCategory.PROVISIONAL;
};

/**
 * Maps an OrderItem or OrderItemLight object to an OrderItemForCreation object.
 *
 * @param {OrderItem | OrderItemLight} orderItem - The order item object to map.
 * @returns {OrderItemForCreation} - The mapped order item for creation object.
 */
export const mapItemToItemForCreation = (
  orderItem: OrderItem | OrderItemLight
): OrderItemForCreation => {
  return {
    product: {
      id: orderItem.product.id
    },
    tags: [],
    itemComponents: orderItem.itemComponents?.map((item) => {
      const itemToCreate: OrderItemComponentForCreation = {
        componentType: item.componentType,
        svgLayer: item?.svgLayer
      };
      if (item.material?.id) {
        itemToCreate.material = { id: item.material?.id };
      }
      if (item.shape?.id) {
        itemToCreate.shape = { id: item.shape.id };
      }
      if (item.shade?.id) {
        itemToCreate.shade = { id: item.shade.id };
      }
      if (item.structures?.length) {
        itemToCreate.structures = item.structures.map((structure) => {
          return {
            id: structure.structure.id,
            positions: structure.positions
          };
        });
      }
      if (item.teethPositions?.length) {
        itemToCreate.teethPositions = item.teethPositions;
      }
      if (item.brandReference?.id) {
        itemToCreate.brandReference = { id: item.brandReference.id };
      }
      if (item.angulation?.id) {
        itemToCreate.angulation = { id: item.angulation.id };
      }
      if (item.aspect?.id) {
        itemToCreate.aspect = { id: item.aspect.id };
      }
      if (item.implantAttachment) {
        itemToCreate.implantAttachment = item.implantAttachment;
      }
      if (item.injectionPositions) {
        itemToCreate.injectionPositions = item.injectionPositions;
      }
      if (item.stumpPositions?.length) {
        itemToCreate.stumpPositions = item.stumpPositions;
      }
      if (item.toothStratificationTechnique) {
        itemToCreate.toothStratificationTechnique = item.toothStratificationTechnique;
      }
      return itemToCreate;
    })
  };
};
