import { TabsType } from '../../../models/navigation.tsx';
import { WorkflowStepEnum } from '../../../enum/workflow-step.ts';
import i18next from 'i18next';
import { ProductionPagePermissions } from '../../../models/connected-user.tsx';
import { OrdersIndicators } from '../../../models/order.tsx';

/**
 * Returns all types allowed for connected user
 * @param connectedUserPermissions
 * @param ordersIndicators
 */
export const getProductionPageTabs = (
  connectedUserPermissions: ProductionPagePermissions,
  ordersIndicators?: OrdersIndicators
): TabsType[] => {
  const allowedTabs: TabsType[] = [];

  if (connectedUserPermissions.canAccessValidationTab) {
    allowedTabs.push({
      label: i18next.t(`order.steps.${WorkflowStepEnum.VALIDATION}`, { ns: 'common' }),
      to: '/production/validation',
      counter: ordersIndicators?.ordersToValidate ?? undefined
    });
  }
  if (connectedUserPermissions.canAccessModelingTab) {
    allowedTabs.push({
      label: i18next.t(`order.steps.${WorkflowStepEnum.MODELING}`, { ns: 'common' }),
      to: '/production/design',
      counter: ordersIndicators?.ordersToDesign ?? undefined
    });
  }
  if (connectedUserPermissions.canAccessManufacturingTab) {
    allowedTabs.push({
      label: i18next.t(`order.steps.${WorkflowStepEnum.MANUFACTURING}`, { ns: 'common' }),
      to: '/production/manufacturing',
      counter: ordersIndicators?.ordersToManufacture ?? undefined
    });
  }
  if (connectedUserPermissions.canAccessAssemblyTab) {
    allowedTabs.push({
      label: i18next.t(`order.steps.${WorkflowStepEnum.ASSEMBLY}`, { ns: 'common' }),
      to: '/production/assembly',
      counter: ordersIndicators?.ordersToAssemble ?? undefined,
      disabled: true
    });
  }
  if (connectedUserPermissions.canAccessControlTab) {
    allowedTabs.push({
      label: i18next.t(`order.steps.${WorkflowStepEnum.CONTROL}`, { ns: 'common' }),
      to: '/production/control',
      disabled: false,
      counter: ordersIndicators?.ordersToControl ?? undefined
    });
  }
  if (connectedUserPermissions.canAccessDeliveryTab) {
    allowedTabs.push({
      label: i18next.t(`order.steps.${WorkflowStepEnum.DELIVERY}`, { ns: 'common' }),
      to: '/production/shipping',
      counter: ordersIndicators?.ordersToShip ?? undefined
    });
  }

  return allowedTabs;
};
