import React from 'react';
import styles from './production-page.module.scss';
import { Tabs } from '@platform-storybook/circlestorybook';
import { useAppSelector } from '../../../store/hooks.tsx';
import { getProductionPageTabs } from './production-page.utils.ts';
import { ProductionPagePermissions } from '../../../models/connected-user.tsx';
import { useGetOrdersIndicatorsQuery } from '../../../services/orders-api.services.ts';
import { getProductionPagePermissionsSelector } from '../../../store/auth/permissions.selectors.tsx';

const ProductionPage = ({ children }: { children: React.ReactNode }) => {
  const { data: ordersIndicators } = useGetOrdersIndicatorsQuery('');
  const connectedUserPermissions: ProductionPagePermissions = useAppSelector(
    getProductionPagePermissionsSelector
  );
  return (
    <>
      <div className={styles['production-page__tab']}>
        <Tabs
          tabs={getProductionPageTabs(connectedUserPermissions, ordersIndicators)}
          id={'production'}
          // We need this so the first tab available is always selected (tabs depends on user permissions)
          activeTabId={0}></Tabs>
      </div>
      <div className={styles['production-page__content']}>{children}</div>
    </>
  );
};

export default ProductionPage;
