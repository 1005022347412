import { createSelector } from '@reduxjs/toolkit';
import { MapReducer, Position, ProductBubble } from '../../models/map';
import { ToothShadeEnum } from '../../enum/component';
import { OrderItemComponentStructure, OrdersReducer } from '../../models/order';

import {
  CursorEnum,
  NotAllowedCursors,
  SelectionContextEnum,
  ToothSelectionEnum,
  ZoneLinkEnum
} from '../../enum/map.enum';
import { sortedPositionsString } from '../../enum/position.enum';
import { PositionKey, PositionKeyString } from '../../models/position';
import { ColorPropsEnum, FamilyColorEnum, SimpleColorsEnum } from '../../enum/color.enum';
import { TeethSelectionMode } from '../../enum/product.enum';
import { currentItemSelector } from '../orders/orders.selectors';
import { emptyBubble } from './map.utils.tsx';
import { productCustomizationSelector } from '../product-customization/productCustomization.selectors.tsx';

interface ReducerState {
  mapState: MapReducer;
  ordersState: OrdersReducer;
}

interface ComponentsForTeethMap {
  framePositions: number[][];
  stumpPositions: number[][];
}

const positionsGroupedByProductId = (
  positions: Array<Position>
): { [key: string]: Array<number> } => {
  return positions.reduce((groupedPositions: Record<string, number[]>, position: Position) => {
    if (position.productIds?.length) {
      position.productIds.forEach((productId) => {
        if (!groupedPositions[productId.uniqueProductId]) {
          groupedPositions[productId.uniqueProductId] = [];
        }
        groupedPositions[productId.uniqueProductId].push(+(position.notation as PositionKeyString));
      });
    } else if (ToothSelectionEnum.SELECTED === position.selection) {
      if (!groupedPositions['-1']) {
        groupedPositions['-1'] = [];
      }
      groupedPositions['-1'].push(+(position.notation as PositionKeyString));
    }
    return groupedPositions;
  }, {});
};

/**
 * Computes all array of values for components needed for the patientMouth in teeth map to work
 * In short , all position number must be sorted in array by productId
 *
 * @param {Position[]} positions
 * @returns {ComponentsForTeethMap}
 */
const computeComponentsForTeethMapByProduct = (
  positions: Array<Position>
): ComponentsForTeethMap => {
  const framePositions = positionsGroupedByProductId(
    positions.filter((position) => position.frame)
  );
  const stumpPositions = positionsGroupedByProductId(
    positions.filter((position) => position.stump)
  );

  return {
    framePositions: Object.keys(framePositions).map((productId) => framePositions[productId]),
    stumpPositions: Object.keys(stumpPositions).map((productId) => stumpPositions[productId])
  };
};

export const mapSelector = (state: ReducerState) => {
  return state?.mapState?.positions;
};

export const mapContextSelector = (state: ReducerState) => {
  return state?.mapState?.mapContext;
};

const mapTeethShades = (
  positions: Array<Position>
): { [key: string]: Array<number | undefined> } => {
  const shades: { [key: string]: Array<number | undefined> } = {};
  for (const shade in ToothShadeEnum) {
    const value = positions
      .filter((position) => shade === position.teethShade)
      .map((position) => (position?.notation ? +position.notation : undefined));
    if (value?.length) {
      shades[shade.toLowerCase()] = value;
    }
  }

  return shades;
};

// Build Svg layer object for patientMouth storybook (EXTRACT, INLAY, STUMP...)
export const mapSvgLayerSelector = createSelector([mapSelector], (positions) => {
  const positionList = Object.values(positions);
  const componentForTeethMap = computeComponentsForTeethMapByProduct(positionList);

  // Initialize the result object
  const computingSvgLayers: {
    [key: string]: {
      notations: number[];
      uniqueProductId: string;
    }[];
  } = {};

  // Loop through each entry in the object
  Object.values(positionList).forEach((position) => {
    const notation = parseInt(position.notation!);
    const svgLayers = position['svgLayers'];

    let uniqueProductIds = position?.productIds?.map((product) => product.uniqueProductId);

    if (uniqueProductIds.length === 0) {
      // add temporary id for product being added on the map
      uniqueProductIds = ['tempId'];
    }

    svgLayers?.forEach((layer) => {
      if (!computingSvgLayers[layer]) {
        computingSvgLayers[layer] = []; // Initialize the notation array for this layer if it doesn't exist
      }

      uniqueProductIds.forEach((uniqueProductId) => {
        // Find the appropriate array range for this uniqueProductId
        const notationRange = computingSvgLayers[layer].find(
          (notation) => notation.uniqueProductId === uniqueProductId
        );

        if (notationRange) {
          notationRange.notations.push(notation); // Add the notation to the existing range
        } else {
          computingSvgLayers[layer].push({ uniqueProductId, notations: [notation] }); // Create a new range
        }
      });
    });
  });

  // Remove uniqueProductId keys in computingSvgLayers object
  const svgLayersMapping = Object.keys(computingSvgLayers).reduce(
    (acc: { [key: string]: number[][] }, key) => {
      acc[key] = computingSvgLayers[key].map((group) => group.notations);
      return acc;
    },
    {}
  );

  return {
    ...svgLayersMapping,
    EXTRACT: positionList
      .map((position) => (position.extract && position.notation ? +position.notation : undefined))
      .filter((element) => element !== undefined),
    MISSING: positionList
      .map((position) => (position.missing && position.notation ? +position.notation : undefined))
      .filter((element) => element !== undefined),
    STUMP: componentForTeethMap.stumpPositions,
    FRAME: componentForTeethMap.framePositions
  };
});

export const teethShadesSelector = createSelector([mapSelector], (positions) => {
  const positionList = Object.values(positions);
  return mapTeethShades(positionList);
});

export const teethBubblesSelector = createSelector(
  [mapSelector, productCustomizationSelector],
  (
    positions: { [key: string]: Position },
    activeCustomizations
  ): { [position: number]: ProductBubble } => {
    if (activeCustomizations?.displayStructureTool) {
      // When structure tool is active, displays empty bubble on avaible tooth
      return Object.entries(positions)
        .filter(([position]) => positions[position].selection === ToothSelectionEnum.SELECTED)
        .reduce((acc, [key, position]) => {
          acc = {
            ...acc,
            [+key]: position?.bubble ?? emptyBubble
          };
          return acc;
        }, {});
    } else {
      return Object.entries(positions)
        .filter(([position]) => positions[position]?.bubble)
        .reduce((acc, [key, position]) => {
          acc = {
            ...acc,
            [+key]: position?.bubble
          };
          return acc;
        }, {});
    }
  }
);

export const activeTeethSelector = createSelector([mapSelector], (positions) => {
  const positionList = Object.values(positions);
  return positionList
    .map((position) =>
      position?.notation && ToothSelectionEnum.SELECTED === position.selection
        ? +position?.notation
        : undefined
    )
    .filter((element) => element !== undefined);
});

export const activeStructuresSelector = createSelector(
  [mapSelector],
  (positions): OrderItemComponentStructure[] => {
    return Object.entries(positions)
      .filter((position) => !position[1]?.productIds?.length)
      .reduce((acc, [key, position]) => {
        const structure = position?.teethStructure;
        if (structure) {
          const existingEntry = acc.find(
            (entry: OrderItemComponentStructure) => entry.structure.code === structure.code
          );
          if (existingEntry) {
            existingEntry.positions.push(+key as PositionKey);
          } else {
            acc.push({ structure, positions: [+key as PositionKey] });
          }
        }
        return acc;
      }, [] as OrderItemComponentStructure[]);
  }
);

export const cursorsSelector = createSelector(
  [mapSelector, mapContextSelector, currentItemSelector, productCustomizationSelector],
  (positions, mapContext, currentItem, activeCustomizations) => {
    const cursors: { [key: number]: CursorEnum } = { ...NotAllowedCursors };
    const isStructureSelection =
      mapContext?.teethSelectionMode === TeethSelectionMode.STRUCTURE_TOOTH;
    Object.keys(positions).forEach((position) => {
      if (isStructureSelection) {
        switch (positions[position].structureSelection) {
          case ToothSelectionEnum.SELECTABLE:
            cursors[+position] = CursorEnum.ADD;
            break;
          case ToothSelectionEnum.SELECTED:
            cursors[+position] =
              activeCustomizations?.teethStructure === positions[position].teethStructure?.code
                ? CursorEnum.REMOVE
                : CursorEnum.ADD;
            break;
          default:
            cursors[+position] = CursorEnum.NOT_ALLOWED;
            break;
        }
      } else {
        switch (positions[position].selection) {
          case ToothSelectionEnum.UNSELECTABLE:
            cursors[+position] = CursorEnum.NOT_ALLOWED;
            break;
          case ToothSelectionEnum.SELECTABLE:
            if (mapContext?.teethSelectionMode === TeethSelectionMode.SINGLE_TOOTH) {
              cursors[+position] = currentItem?.product ? CursorEnum.ADD : CursorEnum.POINTER;
            } else if (
              !mapContext?.userAction ||
              mapContext?.userAction === SelectionContextEnum.RANGE_ENDED ||
              mapContext?.userAction === SelectionContextEnum.ZONE_ENDED
            ) {
              cursors[+position] = CursorEnum.START;
            } else if (
              mapContext?.userAction === SelectionContextEnum.RANGE_STARTED ||
              mapContext?.userAction === SelectionContextEnum.ZONE_STARTED
            ) {
              cursors[+position] = CursorEnum.END;
            } else {
              cursors[+position] = CursorEnum.POINTER;
            }
            break;
          case ToothSelectionEnum.SELECTED:
            if (
              mapContext?.userAction === SelectionContextEnum.ZONE_STARTED &&
              position === mapContext.start
            ) {
              cursors[+position] = CursorEnum.END;
            }
            if (mapContext?.teethSelectionMode === TeethSelectionMode.SINGLE_TOOTH) {
              cursors[+position] = CursorEnum.REMOVE;
            }
            break;
        }
      }
    });

    return cursors;
  }
);

export const zoneLinkPropsSelector = createSelector([mapSelector], (positions) => {
  let startZone: PositionKeyString | null = null;
  let endZone: PositionKeyString | null = null;
  let nextStartZone: PositionKeyString | null = null;
  const zoneLinkProps: Array<{
    startPosition: PositionKey;
    endPosition: PositionKey;
    color: SimpleColorsEnum.GREY | SimpleColorsEnum.PINK;
  }> = [];

  sortedPositionsString.forEach((position: PositionKeyString) => {
    switch (positions[position]?.zone_link) {
      case ZoneLinkEnum.START:
        startZone = position;
        break;
      case ZoneLinkEnum.END:
        endZone = position;
        break;
      case ZoneLinkEnum.END_START:
        if (startZone) {
          endZone = position;
          nextStartZone = position;
        }
        break;
    }

    // We have a start and end position for our zone link, we can save it for display
    if (startZone && endZone) {
      zoneLinkProps.push({
        startPosition: +startZone as PositionKey,
        endPosition: +endZone as PositionKey,
        color: ColorPropsEnum.GREY // TODO handle color according to Definitive/Provisional product
      });
      startZone = nextStartZone || null;
      endZone = null;
    }
  });

  return zoneLinkProps;
});

export const lineAndNumberColorsSelector = createSelector([mapSelector], (positions) => {
  const positionList = Object.values(positions);
  return {
    'family-remov': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_REMOV)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-fixed': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_FIXED)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-implant': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_IMPLANT)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-guards': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_GUARDS)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-ocr': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_OCR)
      .map((position) => (position?.notation ? +position.notation : undefined))
  };
});

export const diagnosticFromPositionsSelector = createSelector([mapSelector], (positions) => {
  const positionList = Object.values(positions);
  return {
    teethToBeExtracted: positionList
      .filter((position) => position.extract)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    missingTeeth: positionList
      .filter((position) => position.missing)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    naturalStumps: positionList
      .filter((position) => position.stump)
      .map((position) => (position?.notation ? +position.notation : undefined))
  };
});
