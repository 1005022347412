import styles from './not-found-page.module.scss';
import { Text, Link } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const NotFoundPage = () => {
  const { t } = useTranslation(['notFound']);
  return (
    <>
      <div className={styles['not-found-page__main']}>
        <div className={styles['not-found-page__main__image']}></div>
        <div className={styles['not-found-page__main__text']}>
          <Text bold label={t('title', { ns: 'notFound' })} size="s" type="title" />
          <Text label={t('text', { ns: 'notFound' })} />
          <Link
            label={t('link', { ns: 'notFound' })}
            href="/"
            className={styles['not-found-page__links__column__item']}
          />
        </div>
      </div>
      <section className={styles['not-found-page__links']}>
        <ul className={styles['not-found-page__links__column']}>
          <li className={styles['not-found-page__links__column__item']}>
            <FontAwesomeIcon
              icon={faCircle}
              className={styles['not-found-page__links__column__item__point']}
            />
            <Link label={t('link.dashboard', { ns: 'common' })} href="/dashboard"></Link>
          </li>
          <li className={styles['not-found-page__links__column__item']}>
            <FontAwesomeIcon
              icon={faCircle}
              className={styles['not-found-page__links__column__item__point']}
            />
            <Link label={t('link.production', { ns: 'common' })} href="/production"></Link>
          </li>
          <li className={styles['not-found-page__links__column__item']}>
            <FontAwesomeIcon
              icon={faCircle}
              className={styles['not-found-page__links__column__item__point']}
            />
            <Link label={t('link.dentists', { ns: 'common' })} href="/dentist"></Link>
          </li>
          <li className={styles['not-found-page__links__column__item']}>
            <FontAwesomeIcon
              icon={faCircle}
              className={styles['not-found-page__links__column__item__point']}
            />
            <Link label={t('link.billing', { ns: 'common' })} href="/billing"></Link>
          </li>
        </ul>
        <ul className={styles['not-found-page__links__column']}>
          <li className={styles['not-found-page__links__column__item']}>
            <FontAwesomeIcon
              icon={faCircle}
              className={styles['not-found-page__links__column__item__point']}
            />
            <Link label={t('link.myProfile', { ns: 'common' })} href="/profile"></Link>
          </li>
          <li className={styles['not-found-page__links__column__item']}>
            <FontAwesomeIcon
              icon={faCircle}
              className={styles['not-found-page__links__column__item__point']}
            />
            <Link label={t('link.myLaboratory', { ns: 'common' })} href="/preferences"></Link>
          </li>
        </ul>
      </section>
    </>
  );
};

export default NotFoundPage;
