import React from 'react';
import stylesDataGrid from '../datagrid-feature.module.scss';

const DatagridHeader = ({ children }: { children: React.JSX.Element[] | string[] }) => {
  return (
    <div>
      <div className={stylesDataGrid['datagrid-feature__header-two-lines-ellipsis']}>
        {children}
      </div>
    </div>
  );
};

export default DatagridHeader;
