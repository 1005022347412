import { useEffect, useState } from 'react';
import styles from './change-step-form.module.scss';
import {
  AttentionBox,
  Box,
  Button,
  RadioList,
  TextareaField
} from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { attentionBoxErrorSelector } from '../../../../store/feedback/feedback.selector';
import { getMessageError } from '../../../../utils/utils';
import {
  useAddCommentToManufacturingOrderMutation,
  useGetOneManufacturingOrderQuery,
  usePatchManufacturingOrderMutation
} from '../../../../services/manufacturing-orders-api.services';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import i18next from '../../../../i18n';
import useForm from '../../../../utils/useForm';
import {
  useAddCommentToOrderMutation,
  usePatchOrderMutation
} from '../../../../services/orders-api.services';
import { Order, OrderForUpdate } from '../../../../models/order';
import {
  WorkflowModelingStepsAuto,
  WorkflowStepEnum,
  WorkFlowStepPreModelingEnum,
  WorkflowPostModelingStepEnum
} from '../../../../enum/workflow-step';
import { RadioListOptions } from '../../../../models/form';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import { ToastType } from '../../../../enum/feedback';
import { ManufacturingOrder } from '../../../../models/manufacturing-order';
import {
  isManufacturingOrderFullCircleCadCompliant,
  isManufacturingOrderOneDesignCompliant
} from '../../../../utils/order.utils';
import SkeletonList from '../../../../features/skeleton-list/SkeletonList';

type props = {
  onSubmitChangeStepOrderCallback: () => void;
  orderNumber: string;
  currentStep: WorkflowStepEnum;
};

const ChangeStepForm = ({ onSubmitChangeStepOrderCallback, orderNumber, currentStep }: props) => {
  const { t } = useTranslation(['dashboard']);
  const dispatch = useAppDispatch();
  const [patchManufacturingOrder, patchManufacturingOrderState] =
    usePatchManufacturingOrderMutation();
  const [addCommentToOrder] = useAddCommentToOrderMutation();
  const [addCommentToManufacturingOrder] = useAddCommentToManufacturingOrderMutation();
  const [patchOrder, patchOrderState] = usePatchOrderMutation();
  const {
    data: manufacturingOrder,
    isLoading: isManufacturingOrderLoading,
    isSuccess: isSuccessManufacturingOrder
  } = useGetOneManufacturingOrderQuery(orderNumber as string);

  const [currentPatchingData, setCurrentPatchingData] =
    useState<Partial<Order | ManufacturingOrder>>();

  // Selectors
  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);

  const getAvailableWorkflowSteps = (): WorkflowStepEnum[] => {
    let availableWorkflowSteps: WorkflowStepEnum[] = [];

    if (manufacturingOrder) {
      if (isManufacturingOrderFullCircleCadCompliant(manufacturingOrder)) {
        availableWorkflowSteps = Object.values(WorkflowStepEnum);
      } else {
        availableWorkflowSteps = [
          ...Object.values(WorkFlowStepPreModelingEnum),
          WorkflowStepEnum.MODELING,
          ...Object.values(WorkflowPostModelingStepEnum)
        ];
      }
    }
    return availableWorkflowSteps;
  };

  const buildHelperText = (step: WorkflowStepEnum): string | undefined => {
    let helperText = undefined;

    if (manufacturingOrder) {
      switch (step) {
        case WorkflowStepEnum.MODELING:
        case WorkflowStepEnum.MODELING_DESIGN:
          {
            if (isManufacturingOrderFullCircleCadCompliant(manufacturingOrder)) {
              helperText = i18next.t(`changeStepModal.helperText.${step}_cad`, {
                ns: 'dashboard'
              });
            } else if (isManufacturingOrderOneDesignCompliant(manufacturingOrder)) {
              helperText = i18next.t(`changeStepModal.helperText.${step}_one`, {
                ns: 'dashboard'
              });
            } else {
              helperText = i18next.t(`changeStepModal.helperText.${step}`, {
                ns: 'dashboard'
              });
            }
          }

          break;
        default:
          helperText = i18next.t(`changeStepModal.helperText.${step}`, {
            ns: 'dashboard'
          });
      }
    }
    return helperText;
  };

  const getAllPreviousValidSteps = (): RadioListOptions[] => {
    const availableWorkflowSteps: WorkflowStepEnum[] = getAvailableWorkflowSteps();
    const availableOptions: RadioListOptions[] = [];
    // the current step can be modeling_design
    let step = currentStep;

    if (
      manufacturingOrder &&
      !isManufacturingOrderFullCircleCadCompliant(manufacturingOrder) &&
      currentStep === WorkflowStepEnum.MODELING_DESIGN
    ) {
      // For order non compliant CAD, there is only modeling step proposed to the user (not modeling_design). But the current step could be modeling_design, so change it to modeling
      step = WorkflowStepEnum.MODELING;
    }
    const previousSteps = step
      ? availableWorkflowSteps.filter(
          (enumValue: WorkflowStepEnum) =>
            availableWorkflowSteps.indexOf(enumValue) < availableWorkflowSteps.indexOf(step) &&
            !WorkflowModelingStepsAuto.includes(enumValue)
        )
      : [];
    previousSteps.forEach((step) => {
      availableOptions.push({
        label: i18next.t(`order.steps.${step}`, {
          ns: 'common'
        }),
        value: `${step}`,
        isDisabled: step === WorkflowStepEnum.ASSEMBLY,
        helperText: buildHelperText(step)
      });
    });
    return availableOptions;
  };

  const previousSteps = getAllPreviousValidSteps();

  useEffect(() => {
    // When patching data is updated, it means form was submitted
    // So we first patch the related manufacturingOrder
    if (currentPatchingData) {
      patchManufacturingOrder(currentPatchingData as unknown as Partial<ManufacturingOrder>);
      addCommentToManufacturingOrder({ orderNumber, comment: values.comment as string });
    }
  }, [currentPatchingData]);

  useEffect(() => {
    // And when manufacturingOrder is patched,
    // we then patch the related order
    if (patchManufacturingOrderState.isSuccess) {
      patchOrder(currentPatchingData as OrderForUpdate);
      addCommentToOrder({ orderNumber, comment: values.comment as string });
    }
  }, [patchManufacturingOrderState.isSuccess]);

  useEffect(() => {
    if (patchOrderState.isSuccess) {
      dispatch(
        feedbackActions.setToast({
          message: t('changeStepModal.success', { ns: 'dashboard' }),
          type: ToastType.SUCCESS
        })
      );
      onSubmitChangeStepOrderCallback();
    }
  }, [patchOrderState.isSuccess]);

  useEffect(() => {
    // select radio button default value
    handleSelect(previousSteps?.slice(-1)[0]?.value, 'previousStep');
  }, [isSuccessManufacturingOrder]);

  const onSubmit = () => {
    // When submitting, we put the patching data in a state, so we can use it to patch order & manu
    setCurrentPatchingData({
      orderNumber: orderNumber,
      currentStep: values.previousStep as WorkflowStepEnum
    });
  };

  const { values, errors, handleSubmit, handleSelect, handleBlur, handleChange } = useForm(
    {
      previousStep: previousSteps?.slice(-1)[0]?.value,
      comment: undefined
    },
    onSubmit
  );

  return (
    <>
      {patchManufacturingOrderState.error && attentionBoxError ? (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={getMessageError(patchManufacturingOrderState.error)}
          className={styles['change-step-form__attention-box']}
        />
      ) : (
        ''
      )}
      {patchOrderState.error && attentionBoxError ? (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={getMessageError(patchOrderState.error)}
          className={styles['change-step-form__attention-box']}
        />
      ) : (
        ''
      )}
      <Box color={ColorPropsEnum.WHITE}>
        {isManufacturingOrderLoading && (
          <SkeletonList heightItem="30px" widthItem="300px" count={6}></SkeletonList>
        )}
        {!isManufacturingOrderLoading && previousSteps && (
          <form onSubmit={handleSubmit} className={styles['change-step-form__form']}>
            <RadioList
              title={t('changeStepModal.label', { ns: 'dashboard' })}
              options={previousSteps}
              selectedValue={values?.previousStep}
              onClick={(newValue: string) => handleSelect(newValue, 'previousStep')}
            />
            <TextareaField
              data-cy="changeStepModalComment"
              id={'comment'}
              name="comment"
              label={t('changeStepModal.comment')}
              value={values.comment}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={errors?.comment ? t('changeStepModal.commentMandatory') : undefined}
              variant={errors.comment ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            />
            <div className="form__submit-button form__submit-button--right">
              <Button
                label={t('changeStepModal.action', { ns: 'dashboard' })}
                isLoading={patchManufacturingOrderState.isLoading || patchOrderState.isLoading}
                type="submit"
                isDisabled={isManufacturingOrderLoading}
              />
            </div>
          </form>
        )}
      </Box>
    </>
  );
};

export default ChangeStepForm;
