import React, { useState } from 'react';
import styles from './create-clinic-form.module.scss';
import { Button, TextField, Dropdown, Box } from '@platform-storybook/circlestorybook';
import { Country } from '../../../../../../enum/user';
import { useTranslation } from 'react-i18next';
import { Establishment } from '../../../../../../models/establishment';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';

type Props = {
  establishment: Establishment;
  setSideBarOpenedCallback: (value: Establishment) => void;
  isNewClinicCreated: boolean;
};

const CreateClinicForm = ({
  establishment,
  setSideBarOpenedCallback,
  isNewClinicCreated
}: Props) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string | undefined>(establishment.name);
  const [address, setAddress] = useState<string | undefined>(establishment.address?.address);
  const [additionalAddress, setAdditionalAddress] = useState<string | undefined>(
    establishment.address?.additionalAddress
  );
  const [zipCode, setZipCode] = useState<string | undefined>(establishment.address?.zipCode);
  const [city, setCity] = useState<string | undefined>(establishment.address?.city);
  const [country, setCountry] = useState<string | undefined>(establishment.address?.country);
  const [region, setRegion] = useState<string | undefined>(establishment.address?.region);

  const [isShowNameMandatoryMessage, setIsShowNameMandatoryMessage] = useState(false);
  const [isShowAddressMandatoryMessage, setIsShowAddressMandatoryMessage] = useState(false);
  const [isShowZipCodeMandatoryMessage, setIsShowZipCodeMandatoryMessage] = useState(false);
  const [isShowCityMandatoryMessage, setIsShowCityMandatoryMessage] = useState(false);
  const [isShowCountryMandatoryMessage, setIsShowCountryMandatoryMessage] = useState(false);

  const buttonLabel = isNewClinicCreated
    ? t('action.update', { ns: 'common' })
    : t('action.create', { ns: 'common' });

  const showMandatoryMessages = () => {
    setIsShowNameMandatoryMessage(!name);
    setIsShowAddressMandatoryMessage(!address);
    setIsShowZipCodeMandatoryMessage(!zipCode);
    setIsShowCityMandatoryMessage(!city);
    setIsShowCountryMandatoryMessage(!country);
  };

  const countries = [
    Object.values(Country).map((country) => {
      return {
        label: t(`countries.${country.toLowerCase()}`, { ns: 'common' }),
        value: country
      };
    })
  ];

  const formIsValid = name && address && zipCode && city && country;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formIsValid) {
      showMandatoryMessages();
      return;
    }

    establishment = {
      ...establishment,
      ...{
        name: name,
        address: {
          address: address,
          additionalAddress: additionalAddress,
          zipCode: zipCode,
          city: city,
          country: country,
          region: region
        }
      }
    };

    setSideBarOpenedCallback(establishment);
  };

  const onSelectCountry = (value: string) => {
    setCountry(value);
    setIsShowCountryMandatoryMessage(!value);
  };

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form
        data-cy="createClinicForm"
        onSubmit={handleSubmit}
        className={styles['create-clinic-form']}>
        <TextField
          className={styles['create-clinic-form__input']}
          data-cy="clinicNameInput"
          id={'name'}
          label={t('createDentist.establishment.createClinicForm.name', {
            ns: 'dentist'
          })}
          value={name}
          type={'text'}
          onBlur={() => setIsShowNameMandatoryMessage(!name)}
          onChange={(evt: Event) => setName((evt.target as HTMLInputElement).value)}
          size={'s'}
          helperText={
            isShowNameMandatoryMessage
              ? t('createDentist.establishment.createClinicForm.nameMandatory', {
                  ns: 'dentist'
                })
              : undefined
          }
          variant={isShowNameMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('createDentist.establishment.createClinicForm.placeholder.clinic', {
            ns: 'dentist'
          })}
        />

        <TextField
          className={styles['create-clinic-form__input']}
          id={'address'}
          data-cy="clinicAddressInput"
          label={t('createDentist.establishment.createClinicForm.address', {
            ns: 'dentist'
          })}
          value={address}
          type={'text'}
          onBlur={() => setIsShowAddressMandatoryMessage(!address)}
          onChange={(evt: Event) => setAddress((evt.target as HTMLInputElement).value)}
          size={'s'}
          helperText={
            isShowAddressMandatoryMessage
              ? t('createDentist.establishment.createClinicForm.addressMandatory', {
                  ns: 'dentist'
                })
              : undefined
          }
          variant={isShowAddressMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('createDentist.establishment.createClinicForm.placeholder.adress', {
            ns: 'dentist'
          })}
        />
        <TextField
          className={styles['create-clinic-form__input']}
          id={'additionalAddress'}
          label={`${t('createDentist.establishment.createClinicForm.additionalAddress', {
            ns: 'dentist'
          })} ${'(' + t('form.optional', { ns: 'common' }) + ')'}`}
          value={additionalAddress}
          type={'text'}
          onChange={(evt: Event) => setAdditionalAddress((evt.target as HTMLInputElement).value)}
          size={'s'}
          placeholder={t('createDentist.establishment.createClinicForm.additionalAddress', {
            ns: 'dentist'
          })}
        />
        <TextField
          className={styles['create-clinic-form__input-zipcode']}
          data-cy="clinicZipCodeInput"
          id={'zipCode'}
          label={t('createDentist.establishment.createClinicForm.zipCode', {
            ns: 'dentist'
          })}
          value={zipCode}
          type={'text'}
          onBlur={() => setIsShowZipCodeMandatoryMessage(!zipCode)}
          onChange={(evt: Event) => setZipCode((evt.target as HTMLInputElement).value)}
          size={'s'}
          helperText={
            isShowZipCodeMandatoryMessage
              ? t('createDentist.establishment.createClinicForm.zipCodeMandatory', {
                  ns: 'dentist'
                })
              : undefined
          }
          variant={isShowZipCodeMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('createDentist.establishment.createClinicForm.zipCode', {
            ns: 'dentist'
          })}
        />
        <TextField
          className={styles['create-clinic-form__input']}
          data-cy="clinicCityInput"
          id={'city'}
          label={t('createDentist.establishment.createClinicForm.city', { ns: 'dentist' })}
          value={city}
          type={'text'}
          onBlur={() => setIsShowCityMandatoryMessage(!city)}
          onChange={(evt: Event) => setCity((evt.target as HTMLInputElement).value)}
          size={'s'}
          helperText={
            isShowCityMandatoryMessage
              ? t('createDentist.establishment.createClinicForm.cityMandatory', {
                  ns: 'dentist'
                })
              : undefined
          }
          variant={isShowCityMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('createDentist.establishment.createClinicForm.city', {
            ns: 'dentist'
          })}
        />
        <TextField
          id={'region'}
          className={styles['create-clinic-form__input']}
          label={`${t('createDentist.establishment.createClinicForm.region', {
            ns: 'dentist'
          })} (${t('form.optional', { ns: 'common' })})`}
          value={region}
          type={'text'}
          onChange={(evt: Event) => setRegion((evt.target as HTMLInputElement).value)}
          size={'s'}
          placeholder={t('createDentist.establishment.createClinicForm.region', {
            ns: 'dentist'
          })}
        />
        <Dropdown
          data={countries}
          value={country}
          data-cy="clinicCountryInput"
          label={t('createDentist.establishment.createClinicForm.country', {
            ns: 'dentist'
          })}
          variant={isShowCountryMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          onChange={(value: string) => onSelectCountry(value)}
          placeholder={t('createDentist.choose', { ns: 'dentist' })}
          helperText={
            isShowCountryMandatoryMessage
              ? t('createDentist.establishment.createClinicForm.countryMandatory', {
                  ns: 'dentist'
                })
              : undefined
          }></Dropdown>
        <div className="form__submit-button form__submit-button--right">
          <Button label={buttonLabel} type="submit" data-cy="submitClinic" />
        </div>
      </form>
    </Box>
  );
};

export default CreateClinicForm;
