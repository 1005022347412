import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';
import styles from './zoom-manager.module.scss';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { ZoomManagerProps } from '../file-manager.model';
import { useTranslation } from 'react-i18next';

const ZoomManager = ({ children, canUploadFile, setHiddenByModal }: ZoomManagerProps) => {
  const [zoomViewerOpened, setZoomViewerOpened] = useState(false);
  const { t } = useTranslation(['fileManager']);

  return (
    <div
      className={[
        styles['zoom-manager'],
        !canUploadFile ? styles['zoom-manager--no-upload'] : ''
      ].join(' ')}>
      {!zoomViewerOpened && (
        <FontAwesomeIcon
          title={t('actions.expand')}
          data-cy="zoomManagerExpand"
          size={'lg'}
          icon={faExpand}
          className={[
            styles['zoom-manager__viewer__zoom'],
            !canUploadFile ? styles['zoom-manager__viewer__zoom--no-upload'] : ''
          ].join(' ')}
          onClick={() => {
            setZoomViewerOpened(!zoomViewerOpened);
            setHiddenByModal(!zoomViewerOpened);
          }}
        />
      )}

      {zoomViewerOpened &&
        createPortal(
          <>
            <div
              className={[
                styles['zoom-manager__viewer__background'],
                zoomViewerOpened
                  ? styles['zoom-manager__viewer__background--fade-in-modal']
                  : styles['zoom-manager__viewer__background--fade-out-modal']
              ].join(' ')}
              onClick={() => {
                setZoomViewerOpened(false);
                setHiddenByModal(false);
              }}></div>

            <div
              data-cy="zoomManagerModal"
              className={[
                styles['zoom-manager__viewer__modal'],
                !canUploadFile ? styles['zoom-manager__viewer__modal--no-upload'] : ''
              ].join(' ')}>
              <div
                className={[
                  styles['zoom-manager__viewer__container'],
                  !canUploadFile ? styles['zoom-manager__viewer__container--no-upload'] : ''
                ].join(' ')}>
                <div className={styles['zoom-manager__viewer__icon']}>
                  <FontAwesomeIcon
                    title={t('actions.compress')}
                    icon={faCompress}
                    size={'lg'}
                    className={[
                      styles['zoom-manager__viewer__zoom'],
                      styles['zoom-manager__viewer__modal__zoom'],
                      !canUploadFile ? styles['zoom-manager__viewer__zoom--no-upload'] : ''
                    ].join(' ')}
                    onClick={() => {
                      setZoomViewerOpened(false);
                      setHiddenByModal(false);
                    }}
                  />
                </div>

                <div className={styles['zoom-manager__viewer__children']}>{children}</div>
              </div>
            </div>
          </>,
          document.getElementById('dialog-root')!
        )}
    </div>
  );
};

export default ZoomManager;
