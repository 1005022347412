import i18next from 'i18next';
import { ColorPropsEnum, MaterialColorEnum } from '../enum/color.enum';
import { ComponentType, MaterialEnum, ToothShadeEnum } from '../enum/component';
import { sortedPositionsNumber } from '../enum/position.enum';
import { Family } from '../enum/product.enum';
import {
  WorkflowComponentManufacturingPendingStepEnum,
  WorkflowComponentManufacturingStepEnum,
  WorkflowPendingStepEnum,
  WorkFlowPendingStepPreModelingEnum,
  WorkflowPostModelingPendingStepEnum,
  WorkflowPostModelingStepEnum,
  WorkflowStepEnum,
  WorkFlowStepPreModelingEnum
} from '../enum/workflow-step';
import { getComponentInItemByType } from '../features/order-manager/teeth-map/utils';
import { ManufacturingOrder, ManufacturingOrderProduct } from '../models/manufacturing-order';
import {
  Order,
  OrderItem,
  OrderItemComponent,
  OrderItemComponentLight,
  OrderItemLight
} from '../models/order';
import { PositionKey } from '../models/position';
import { publicImagesUrl } from './utils';
import { ManufacturingComponentView } from '../models/component.tsx';

export const defaultProductIconUrl = `${publicImagesUrl}gear-solid.svg`;

export const isSubmittingStep = (currentStep: string | undefined): boolean =>
  (currentStep && currentStep === WorkflowStepEnum.SUBMITTING) as boolean;

export const isSubmitted = (
  currentStep?: WorkflowStepEnum | WorkFlowPendingStepPreModelingEnum
): boolean =>
  (currentStep &&
    !(
      currentStep === WorkFlowStepPreModelingEnum.SUBMITTING ||
      currentStep === WorkFlowPendingStepPreModelingEnum.PENDING_VALIDATION
    )) as boolean;

export const isValidationStep = (currentStep: string | undefined): boolean =>
  (currentStep && currentStep === WorkflowStepEnum.VALIDATION) as boolean;

export const isValidated = (currentStep?: WorkflowStepEnum): boolean =>
  (currentStep &&
    !isInPendingStatus(currentStep) &&
    !Object.values(WorkFlowStepPreModelingEnum).includes(
      currentStep as WorkFlowStepPreModelingEnum
    )) as boolean;

export const isInPendingStatus = (
  currentStep?: WorkflowStepEnum | WorkflowPendingStepEnum
): boolean =>
  (currentStep &&
    Object.values(WorkflowPendingStepEnum).includes(
      currentStep as WorkflowPendingStepEnum
    )) as boolean;

export const getNonPendingStep = (
  currentStep?: WorkflowPendingStepEnum | WorkflowStepEnum
): WorkflowStepEnum | undefined => {
  if (!isInPendingStatus(currentStep)) {
    return currentStep as WorkflowStepEnum;
  }
  const nonPendingStepKey = currentStep?.replace('pending_', '').toUpperCase();
  if (nonPendingStepKey && nonPendingStepKey in WorkflowStepEnum) {
    return WorkflowStepEnum[nonPendingStepKey as keyof typeof WorkflowStepEnum];
  }
};

export const getPendingStep = (
  currentStep?: WorkflowStepEnum | WorkflowPendingStepEnum
): WorkflowPendingStepEnum | undefined => {
  if (isInPendingStatus(currentStep)) {
    return currentStep as WorkflowPendingStepEnum;
  }
  const pendingStepKey = ('PENDING_' + currentStep).toUpperCase();
  if (isInPendingStatus(pendingStepKey as WorkflowPendingStepEnum)) {
    return WorkflowPendingStepEnum[pendingStepKey as keyof typeof WorkflowPendingStepEnum];
  }
};

export const isInManufacturingPendingStatus = (component?: ManufacturingComponentView): boolean =>
  ((component?.manufacturingStep &&
    Object.values(WorkflowComponentManufacturingPendingStepEnum).includes(
      component.manufacturingStep as WorkflowComponentManufacturingPendingStepEnum
    )) ||
    (component?.currentStep &&
      WorkflowPostModelingPendingStepEnum.PENDING_CONTROL.toString() ===
        component.currentStep.toString())) as boolean;

export const isModelingStep = (currentStep: string): boolean =>
  (currentStep &&
    (currentStep === WorkflowPendingStepEnum.PENDING_MODELING ||
      currentStep === WorkflowStepEnum.MODELING ||
      currentStep === WorkflowPendingStepEnum.PENDING_MODELING_ANALYZE ||
      currentStep === WorkflowStepEnum.MODELING_ANALYZE ||
      currentStep === WorkflowStepEnum.MODELING_PREPARE ||
      currentStep === WorkflowStepEnum.MODELING_REGISTER ||
      currentStep === WorkflowStepEnum.MODELING_DESIGN ||
      currentStep === WorkflowStepEnum.MODELING_EXPORT)) as boolean;

export const isModeled = (currentStep?: WorkflowStepEnum): boolean =>
  (currentStep &&
    [
      WorkflowStepEnum.MANUFACTURING,
      WorkflowStepEnum.CONTROL,
      WorkflowStepEnum.ASSEMBLY,
      WorkflowStepEnum.SHIPPED,
      WorkflowStepEnum.DELIVERY,
      WorkflowStepEnum.DELIVERED
    ].includes(currentStep as WorkflowPostModelingStepEnum)) as boolean;

export const isDeliveryStep = (currentStep: string | undefined): boolean =>
  (currentStep && currentStep === WorkflowStepEnum.DELIVERY) as boolean;

export const isControlStep = (currentStep: string | undefined): boolean =>
  (currentStep && currentStep === WorkflowStepEnum.CONTROL) as boolean;

export const hasDeliveryStarted = (currentStep: WorkflowStepEnum): boolean => {
  return [WorkflowStepEnum.DELIVERY, WorkflowStepEnum.SHIPPED, WorkflowStepEnum.DELIVERED].includes(
    currentStep as unknown as WorkflowPostModelingStepEnum
  );
};

export const isAutomaticModelisationStep = (currentStep: WorkflowStepEnum): boolean =>
  currentStep &&
  (currentStep === WorkflowStepEnum.MODELING ||
    currentStep === WorkflowStepEnum.MODELING_ANALYZE ||
    currentStep === WorkflowStepEnum.MODELING_REGISTER ||
    currentStep === WorkflowStepEnum.MODELING_EXPORT);

export const canChangeOrderStep = (currentStep?: string): boolean =>
  (currentStep &&
    !isSubmittingStep(currentStep) &&
    !isValidationStep(currentStep) &&
    !isDeliveryStep(currentStep)) as boolean;

/**
 * Find the most advanced manufacturing step that has been reached by ALL components of all products of the manufacturing order
 * Examples:
 *   - 1 component is TO_DESIGN, all others are MANUFACTURED -> return TO_DESIGN
 *   - 1 component is DESIGNED, all others are MANUFACTURED -> return DESIGNED
 *   - 1 component is DESIGNED, all others are TO_MANUFACTURE -> return DESIGNED
 *   - 1 component is TO_DESIGN, all others are MANUFACTURED -> return TO_DESIGN
 * @param manufacturingOrder
 */
export const getMostAdvancedCommonManufacturingStep = (
  manufacturingOrder: ManufacturingOrder
): WorkflowComponentManufacturingStepEnum => {
  const allComponentsSteps = manufacturingOrder.products
    .map((product) => product.components.map((component) => component.manufacturingStep))
    .flat();
  let mostAdvancedStep: WorkflowComponentManufacturingStepEnum | undefined = undefined;

  // We want the most advanced step that has been reach by ALL components
  // Iterate through WorkflowComponentManufacturingStepEnum from the least advanced step to the most advanced
  // If at least one of the components is at this step : this is it
  Object.values(WorkflowComponentManufacturingStepEnum).forEach((step) => {
    const componentsInThisStep = allComponentsSteps.filter(
      (componentStep) => componentStep === step
    );
    if (!mostAdvancedStep && componentsInThisStep.length > 0) {
      mostAdvancedStep = step;
    }
  });

  return mostAdvancedStep ?? WorkflowComponentManufacturingStepEnum.TO_DESIGN;
};

export const getItemComponentAttribute = (
  item: OrderItem,
  componentType: ComponentType
): OrderItemComponent | undefined => {
  return item?.itemComponents?.find((itemComponent) => {
    return itemComponent.componentType === componentType;
  });
};

export const getMaterialColor = (material: MaterialEnum) => {
  return Object.keys(MaterialColorEnum).includes(material)
    ? ColorPropsEnum[material.replace('-', '_') as keyof typeof ColorPropsEnum]
    : ColorPropsEnum.WHITE;
};

export const getCircleCadCompliantProducts = (
  order: ManufacturingOrder
): ManufacturingOrderProduct[] => {
  return order.products?.filter((product) => product.isCompliantCircleCad);
};

export const isManufacturingOrderOneDesignCompliant = (
  order: ManufacturingOrder
): boolean | undefined => {
  if (!order) {
    return false;
  }
  return order.products.length === order.products.filter((product) => product.isOneDesign).length;
};

export const isOrderOneDesignCompliant = (order: Order): boolean | undefined => {
  // validation to one design if all products in the order are one design compliant
  return order?.items?.length === order?.items?.filter((item) => item.product.isOneDesign)?.length;
};

// is the order has all products compliant circle cad
export const isManufacturingOrderFullCircleCadCompliant = (
  manufacturingOrder: ManufacturingOrder
): boolean => {
  const circleCadCompliantProducts = getCircleCadCompliantProducts(manufacturingOrder);
  return circleCadCompliantProducts?.length === manufacturingOrder.products?.length;
};

// is the order has products compliants and not compliants circle cad
export const isPartiallyCircleCadCompliant = (manufacturingOrder: ManufacturingOrder): boolean => {
  const circleCadCompliantProducts = getCircleCadCompliantProducts(manufacturingOrder);
  return (
    circleCadCompliantProducts.length > 0 &&
    circleCadCompliantProducts.length < manufacturingOrder.products?.length
  );
};

export const getSmallOrderNumber = (orderNumber: string) => {
  return orderNumber.slice(-3);
};

export const getTeethOrPartialTeethMaterial = (item: OrderItem | OrderItemLight) => {
  const toothComponent = getComponentInItemByType(item, ComponentType.TOOTH);
  const partialToothComponent = getComponentInItemByType(item, ComponentType.PARTIAL_TOOTH);

  if (toothComponent) {
    return toothComponent?.material?.code as MaterialEnum;
  }

  if (partialToothComponent) {
    return partialToothComponent?.material?.code as MaterialEnum;
  }
  return null;
};

export const getTeethShade = (item: OrderItem | OrderItemLight): ToothShadeEnum | null => {
  const toothComponent = getComponentInItemByType(item, ComponentType.TOOTH);
  const partialToothComponent = getComponentInItemByType(item, ComponentType.PARTIAL_TOOTH);

  if (toothComponent) {
    return toothComponent?.shade?.code as ToothShadeEnum;
  }

  if (partialToothComponent) {
    return partialToothComponent?.shade?.code as ToothShadeEnum;
  }
  return null;
};

export const sortTeethPosition = (teethPosition: PositionKey[]): PositionKey[] => {
  const sortedTeethPosition = [...teethPosition];
  const orderedPositions = [...sortedPositionsNumber];
  return sortedTeethPosition.sort(
    (a, b) => orderedPositions.indexOf(a) - orderedPositions.indexOf(b)
  );
};

export const getToothOrPartialTooth = (
  item: OrderItem | OrderItemLight
): OrderItemComponent | OrderItemComponentLight | undefined => {
  const toothComponent = getComponentInItemByType(item, ComponentType.TOOTH);
  const partialToothComponent = getComponentInItemByType(item, ComponentType.PARTIAL_TOOTH);

  return toothComponent || partialToothComponent;
};

/**
 * Removes occurences of 'provisoire' or 'provisionnal' from a string, then trim it
 * @param {name} string - The string to modify.
 * @returns {string} The modified name.
 */
const removeProvisionnalInfoFromString = (name: string): string => {
  const regex = /provisoire|provisionnal/gi;
  return name.replace(regex, '').trim();
};

/**
 * Returns a single line of product according to the data provided
 * Ex : Complet x{{count}}
 * @param {family} Family - The family of the product.
 * @param {product} string - The name of the product.
 * @param {dentalArch} string - The dental arch of the product.
 * @param {teethMaterial} string - The teeth material of the product.
 * @param {gingivaMaterial} string - The gingiva material of the product.
 * @param {shade} string - The teeth shade of the product.
 * @returns {string} The built line.
 */
export const buildProductLabel = (
  family: Family,
  product: string,
  dentalArch?: string,
  withCount: boolean = true
): string => {
  let label = removeProvisionnalInfoFromString(product) + (withCount ? ' x{{count}}' : '');
  if (family === Family.REMOV && dentalArch) {
    label += ' ' + i18next.t(`arch.${dentalArch}`, { ns: 'component' });
  }
  return label;
};
