import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GingivaShadeEnum,
  MaterialEnum,
  ToothShadeEnum,
  ToothStructureEnum
} from '../../enum/component';
import { ProductCustomizationReducer } from '../../models/product-customization';

const initialProductCustomization: ProductCustomizationReducer = {
  teethShade: undefined,
  gingivaShade: undefined,
  frameMaterial: undefined,
  displayStructureTool: undefined,
  teethStructure: undefined
};

export const productCustomizationSlice = createSlice({
  name: 'productCustomization',
  initialState: initialProductCustomization,
  reducers: {
    setShade: (state, action: PayloadAction<ToothShadeEnum>) => {
      state.teethShade = action.payload;
    },
    setGingivaShade: (state, action: PayloadAction<GingivaShadeEnum>) => {
      state.gingivaShade = action.payload;
    },
    setFrameMaterial: (state, action: PayloadAction<MaterialEnum>) => {
      state.frameMaterial = action.payload;
    },
    setTeethStructure: (state, action: PayloadAction<ToothStructureEnum | undefined>) => {
      state.teethStructure = action.payload;
    },
    setDisplayStructureTool: (state, action: PayloadAction<boolean>) => {
      state.displayStructureTool = action.payload;
    },
    resetProductCustomization: () => initialProductCustomization
  },
  extraReducers: (builder: ActionReducerMapBuilder<ProductCustomizationReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialProductCustomization };
    });
  }
});

export const productCustomizationActions = productCustomizationSlice.actions;
