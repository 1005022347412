import { useEffect, useState } from 'react';
// import styles from './send-to-manufacturing-form.module.scss';
import { Box, Button } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { useUploadToStorageMutation } from '../../../../../../services/orders-api.services';
import { OrderFile } from '../../../../../../models/order';
import {
  addFile,
  displayFileInViewer,
  removeFile
} from '../../../../../../features/file-manager/file.utils';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { deleteFileAction } from '../../../../../../features/file-manager/menu-entry';
import { FileManagerOrientationProp } from '../../../../../../features/file-manager/file-manager.enum';
import FileManagerContainer from '../../../../../../features/file-manager/FileManagerContainer';
import {
  isLoadingFilesSelector,
  orderFile3dToDisplaySelector,
  orderFileImageToDisplaySelector,
  orderFilesSelector,
  orderFileTextureToDisplaySelector
} from '../../../../../../store/orders/orders.selectors';
import { ordersActions } from '../../../../../../store/orders/orders.reducer';
import { feedbackActions } from '../../../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../../../enum/feedback';
import { useSendManufacturingRequestMutation } from '../../../../../../services/manufacturing-orders-api.services';
import { useUploadProductionFileMutation } from '../../../../../../services/files-api.services';
import { ManufacturingOrder } from '../../../../../../models/manufacturing-order';

type props = {
  onSubmitCallback: () => void;
  order: ManufacturingOrder;
};

const SendToManufacturingForm = ({ onSubmitCallback, order }: props) => {
  const { t } = useTranslation(['production']);
  const dispatch = useAppDispatch();
  const orderFiles = useAppSelector(orderFilesSelector);
  const file3dToDisplay = useAppSelector(orderFile3dToDisplaySelector);
  const fileTextureToDisplay = useAppSelector(orderFileTextureToDisplaySelector);
  const fileImageToDisplay = useAppSelector(orderFileImageToDisplaySelector);
  const isLoadingFiles = useAppSelector(isLoadingFilesSelector);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [sendManufacturingRequest, { isSuccess: isSuccessSendManufacturing }] =
    useSendManufacturingRequestMutation();
  const [uploadProductionFile] = useUploadProductionFileMutation();
  const [uploadToStorage] = useUploadToStorageMutation();

  useEffect(() => {
    dispatch(ordersActions.resetOrder());
  }, []);

  useEffect(() => {
    // When editing an order, we need to wait for the files list to load to display a file in the viewer
    displayFileInViewer(orderFiles, orderFiles[orderFiles.length - 1], dispatch);
  }, [orderFiles]);

  const deleteFile = async (fileToDelete: OrderFile): Promise<void> => {
    removeFile(dispatch, orderFiles, fileToDelete, file3dToDisplay);
  };

  const fileActions = (file: OrderFile) => [
    [
      deleteFileAction(file, () => {
        deleteFile(file);
      })
    ]
  ];

  useEffect(() => {
    if (isSuccessSendManufacturing) {
      onSubmitCallback();
      dispatch(
        feedbackActions.setToast({
          message: t('toast.sendManufacturingConfirm'),
          type: ToastType.SUCCESS
        })
      );
      dispatch(ordersActions.resetOrder());
    }
  }, [isSuccessSendManufacturing]);

  const uploadFiles = async (newFilesToUpload: File[]): Promise<void> => {
    // Update new files with loading true
    addFile(dispatch, orderFiles, newFilesToUpload);
  };

  const handleSubmit = async () => {
    try {
      setIsLoadingSubmit(true);
      // Get urls for upload
      const uploadPromises: Promise<string>[] = [];
      for (const file of orderFiles) {
        uploadPromises.push(
          uploadProductionFile({
            orderNumber: order.orderNumber,
            file: file,
            labName: 'CIRCLEONE',
            teethMaterial: order.products[0].components[0].material!,
            productFamily: order.products[0].family!
          }).unwrap()
        );
      }
      const urlUploadFileList = await Promise.all(uploadPromises);

      // Upload on GCP
      const uploadToStoragePromises: Promise<object>[] = [];
      urlUploadFileList.forEach((urls, i) => {
        urls.split('\n').forEach((url) => {
          uploadToStoragePromises.push(
            uploadToStorage({
              url,
              file: orderFiles[i].data!
            }).unwrap()
          );
        });
      });
      await Promise.all(uploadToStoragePromises);

      // Send to manufacturing
      await sendManufacturingRequest({ orderNumber: order.orderNumber });
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  return (
    <>
      {
        <Box color={ColorPropsEnum.WHITE}>
          <form>
            <FileManagerContainer
              orientation={FileManagerOrientationProp.PORTRAIT}
              filesList={orderFiles}
              isReadOnly={isLoadingSubmit}
              displayLabel={false}
              height="calc(100vh - 37rem)"
              isViewerLoading={isLoadingFiles}
              file3DToDisplay={file3dToDisplay}
              fileTextureToDisplay={fileTextureToDisplay}
              fileImageToDisplay={fileImageToDisplay}
              fileActions={fileActions}
              onUploadFileCallback={(newFiles: File[]) => uploadFiles(newFiles)}
              onClickFileCallback={(selectedFile: OrderFile) =>
                displayFileInViewer(orderFiles, selectedFile, dispatch)
              }
            />
            <div className="form__submit-button form__submit-button--right">
              <Button
                data-cy="submit-manufacturing"
                label={t('tabs.design.actions.send_manufacturing')}
                onClick={handleSubmit}
                isLoading={isLoadingFiles || isLoadingSubmit}
                iconLeft="fa-check"
                variant={ColorPropsEnum.SUCCESS}
              />
            </div>
          </form>
        </Box>
      }
    </>
  );
};

export default SendToManufacturingForm;
