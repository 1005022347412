import UserDetails from '../../../features/user-details/UserDetails';
import { useAppSelector } from '../../../store/hooks';
import { connectedUserSelector } from '../../../store/auth/auth.selectors';
import { UserDetailsRefererEnum } from '../../../enum/user';

const ProfilePage = () => {
  const connectedUser = useAppSelector(connectedUserSelector);

  return (
    connectedUser?.email && (
      <UserDetails email={connectedUser.email} referer={UserDetailsRefererEnum.PROFILE_PAGE} />
    )
  );
};

export default ProfilePage;
