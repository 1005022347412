import { useEffect } from 'react';
import styles from './update-tags-form.module.scss';
import { Box, Button, CheckboxList, Text } from '@platform-storybook/circlestorybook';
import { useAppDispatch } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import useForm from '../../../../utils/useForm';
import { usePatchOrderMutation } from '../../../../services/orders-api.services';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import { ToastType } from '../../../../enum/feedback';
import { CheckboxStringItem } from '../../../../models/form';
import { usePatchManufacturingOrderMutation } from '../../../../services/manufacturing-orders-api.services';
import { useGetConnectedUserQuery } from '../../../../services/users-api.services';
import { WorkflowStepEnum, WorkFlowStepPreModelingEnum } from '../../../../enum/workflow-step';

type props = {
  onSubmitUpdateTagsCallback: () => void;
  orderNumber: string;
  currentStep: WorkflowStepEnum;
  currentTags?: string[];
};

const UpdateTagsForm = ({
  onSubmitUpdateTagsCallback,
  orderNumber,
  currentTags,
  currentStep
}: props) => {
  const { t } = useTranslation(['dashboard']);
  const dispatch = useAppDispatch();
  const { data: connectedUser } = useGetConnectedUserQuery();

  const [patchOrder, { isLoading: isLoadingPatchOrder, isSuccess: isSuccessPatchOrder }] =
    usePatchOrderMutation();
  const [
    patchManufacturingOrder,
    { isLoading: isLoadingPatchManufacturingOrder, isSuccess: isSuccessPatchManufacturingOrder }
  ] = usePatchManufacturingOrderMutation();

  const getActiveTagsAsStrings = (): string[] => {
    return (values.tags as Array<CheckboxStringItem>)
      ?.filter((tag) => tag.isChecked)
      ?.map((tag) => tag.value);
  };

  const onSubmit = async () => {
    const tags = getActiveTagsAsStrings();
    await patchOrder({
      orderNumber: orderNumber,
      tags: tags.length ? tags : []
    });
  };

  useEffect(() => {
    if (isSuccessPatchOrder) {
      // If the order is not in modeling step or later, we stop there
      if (
        Object.values(WorkFlowStepPreModelingEnum).includes(
          currentStep as WorkFlowStepPreModelingEnum
        )
      ) {
        dispatch(
          feedbackActions.setToast({
            message: t('updateTagsModal.success'),
            type: ToastType.SUCCESS
          })
        );
        onSubmitUpdateTagsCallback();
      } else {
        // else we also patch manufacturing_order
        const tags = getActiveTagsAsStrings();
        patchManufacturingOrder({
          orderNumber: orderNumber,
          tags: tags.length ? tags : []
        });
      }
    }
  }, [isSuccessPatchOrder]);

  useEffect(() => {
    if (isSuccessPatchManufacturingOrder) {
      dispatch(
        feedbackActions.setToast({
          message: t('updateTagsModal.success'),
          type: ToastType.SUCCESS
        })
      );
      onSubmitUpdateTagsCallback();
    }
  }, [isSuccessPatchManufacturingOrder]);

  const tags: Array<CheckboxStringItem> = Object.values(connectedUser?.laboratory?.tags || []).map(
    (tag) => {
      return {
        value: tag,
        label: tag,
        // We must explicitly set a boolean here cause isChecked is required in storybook
        isChecked: currentTags?.includes(tag) ? true : false
      };
    }
  );

  const { values, handleSubmit, handleCheck } = useForm(
    {
      tags: tags
    },
    onSubmit
  );

  return (
    <Box color={ColorPropsEnum.WHITE} className={styles['update-tags-form__box']}>
      <form onSubmit={handleSubmit} className={styles['update-tags-form__box__form']}>
        {Object.keys(connectedUser?.laboratory?.tags || []).length > 0 ? (
          <CheckboxList
            title={t('updateTagsModal.tags')}
            name="tags"
            data={values.tags}
            onClick={handleCheck}
          />
        ) : (
          <Text label={t('updateTagsModal.noTag')} />
        )}
        <div className="form__submit-button form__submit-button--right">
          <Button
            data-cy="changeTagsButton"
            label={t('updateTagsModal.action')}
            isLoading={isLoadingPatchOrder || isLoadingPatchManufacturingOrder}
            isDisabled={Object.keys(connectedUser?.laboratory?.tags || []).length === 0}
            type="submit"
          />
        </div>
      </form>
    </Box>
  );
};

export default UpdateTagsForm;
