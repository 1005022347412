import { useTranslation } from 'react-i18next';
import { Skeleton, Text, Tooltip, TooltipContent } from '@platform-storybook/circlestorybook';
import styles from './history-tab.module.scss';
import moment from 'moment/moment';
import {
  useGetAllDentistsQuery,
  useGetLabUsersQuery
} from '../../../../../services/users-api.services';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { useGetHistoryQuery } from '../../../../../services/orders-api.services';
import { History } from '../../../../../models/order';
import { HistoryKnownActionEnum } from '../../../../../enum/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

type props = {
  orderNumber: string;
};

const HistoryTab = ({ orderNumber }: props) => {
  const { t } = useTranslation(['history']);

  const historyState = useGetHistoryQuery(orderNumber);
  const labUsersState = useGetLabUsersQuery();
  const dentistUsersState = useGetAllDentistsQuery();

  const getUserLabel = (email: string): string => {
    if (labUsersState.isSuccess && dentistUsersState.isSuccess) {
      const user = labUsersState.data?.find((user) => user.email === email);
      if (user) {
        return `${user.firstName} ${user.lastName}`;
      } else {
        const dentist = dentistUsersState.data?.data?.find((user) => user.email === email);
        if (dentist) {
          return `${dentist.firstName} ${dentist.lastName}`;
        }
      }
    }
    return email;
  };

  const isKnownAction = (history: History): boolean =>
    HistoryKnownActionEnum[history.action as keyof typeof HistoryKnownActionEnum] !== undefined;

  /**
   * Retrieves a localized string corresponding to a known history action if it matches
   * one of the predefined actions in HistoryKnownActionEnum. If the action is unknown or not
   * specified, returns the original action string from the history object.
   *
   * @param {History} history - An object containing the action to be evaluated.
   * @returns {string | undefined} The localized string for the known action or the original
   * action string if not found in the predefined actions.
   */
  const getAction = (history: History): string => {
    const knownAction = isKnownAction(history) ? history.action : undefined;
    return knownAction ? t('history.action.' + knownAction) : history.action;
  };

  const formatTooltip = (history: History): string | undefined => {
    if (history.data) {
      return history.data
        ?.replaceAll('{', '')
        ?.replaceAll('}', '')
        ?.replaceAll('[', '')
        ?.replaceAll(']', '');
    }
  };

  const formatData = (data: string): string =>
    data.replaceAll('"', '').substring(0, data.endsWith('"') ? data.length - 1 : undefined);

  return (
    <ul className={styles['history-tab']}>
      {historyState?.isLoading ? (
        <Skeleton type="text" className="skeleton" width="100%" height="50px" />
      ) : !historyState.data?.data ? (
        <Text label={t('history.noHistory')} />
      ) : (
        historyState.data?.data?.map((history, index) => {
          return (
            <li
              data-cy="history"
              key={`history${history.creationDate}${index}`}
              className={styles['history-tab__line']}>
              <Text
                key={`historyDate${history.creationDate}${index}`}
                className={styles['history-tab__line__date']}
                label={moment(history.creationDate).format('DD MMM HH:mm')}
              />
              <Text
                key={`historyUser${history.creationDate}${index}`}
                className={styles['history-tab__line__user']}
                label={`${getUserLabel(history.userEmail)}`}
                bold
              />
              <Text
                key={`historyValue${history.creationDate}${index}`}
                label={`${getAction(history)}`}
              />
              {history?.data && history.data.length > 0 && (
                <Tooltip>
                  <FontAwesomeIcon
                    className={styles['history-tab__line__icon']}
                    icon={faCircleInfo}
                    color={ColorPropsEnum.GREY}
                  />
                  <TooltipContent>
                    {formatTooltip(history)
                      ?.split(',"')
                      .map((data) => formatData(data))
                      .map((data, dataIndex) => {
                        return (
                          <div key={`historyTooltip${history.creationDate}${index}${dataIndex}`}>
                            {data}
                          </div>
                        );
                      })}
                  </TooltipContent>
                </Tooltip>
              )}
            </li>
          );
        })
      )}
    </ul>
  );
};

export default HistoryTab;
