import { Establishment, EstablishmentReducer } from '../../models/establishment';
import { createSelector } from '@reduxjs/toolkit';
import { establishmentsApi } from '../../services/establishments-api.services';

interface ReducerState {
  establishmentState: EstablishmentReducer;
}

export const clinicListSelector = (state: ReducerState) => state.establishmentState.clinicList;

const clinicsSelector = establishmentsApi.endpoints.getAllClinics.select();

export const clinicListForDropdownSelector = createSelector([clinicsSelector], (clinics) =>
  mapForDropdown(clinics?.data?.data)
);

const mapForDropdown = (establishments: Establishment[] | undefined) => {
  if (establishments && establishments.length > 0) {
    return establishments.map((ets) => {
      return { label: ets.name, value: ets.id };
    });
  }

  return [];
};
