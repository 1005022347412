import { useEffect } from 'react';
import OrderForm from '../../../features/order-form/OrderForm';
import { mapActions } from '../../../store/map/map.reducer';
import { useAppDispatch } from '../../../store/hooks';

const CreateOrderPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(mapActions.resetMap());
  }, []);
  return <OrderForm />;
};

export default CreateOrderPage;
